import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Colors,
  TextVariants,
  Text,
  RegularInput,
  Button,
} from "reusable";
import { useCreator, useAlerts, useUser } from "backend";
import { CreatorProfile, FeedbackRequest } from "models";
import "./GetRating.css";

const GetRating = () => {
  const { userId } = useParams();
  const navigate = useNavigate();

  const {
    creatorProfile,
    getProfile,
    isLoading,
    sendFeedbackRequest,
    isFeedbackRequestLoading,
  } = useCreator();
  const { loggedInUser } = useUser();
  const { position } = (creatorProfile as CreatorProfile) || { position: "" };
  const { addAlert } = useAlerts();
  const [userAnswers, setUserAnswers] = useState<Record<string, string>>({});
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [isAllRequiredAnswered, setIsAllRequiredAnswered] =
    useState<boolean>(false);

  const isOwner = position === "Business Owner";
  const isEmploye = position === "Employee";

  // Add regex patterns to the questions array
  const questions = [
    {
      title: isEmploye ? "Company Name" : "University Name",
      key: isEmploye ? "companyName" : "universityName",
      regex: /^.{2,}$/, // At least 2 characters,
      placeHolder: isEmploye ? "Infosys" : "Pune University",
    },
    {
      title: isEmploye ? "Employer Name" : "Professor Name",
      key: isEmploye ? "employerName" : "professorName",
      regex: /^.{2,}$/, // At least 2 characters,
      placeHolder: "Mr. John Doe",
    },
    {
      title: isEmploye ? "Company Email ID" : "Email ID",
      key: "emailId",
      regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Email validation,
      placeHolder: "john@gmail.com",
    },
  ];

  const handleAnswerChange = (answer: string, questionKey: string) => {
    const question = questions.find((q) => q.key === questionKey);
    setUserAnswers({ ...userAnswers, [questionKey]: answer });

    if (question && question.regex) {
      setErrors({
        ...errors,
        [questionKey]: !question.regex.test(answer),
      });
    }

    const isAllAnswered = questions.every(
      (q) => ({ ...userAnswers, [questionKey]: answer }[q.key]?.length)
    );
    setIsAllRequiredAnswered(isAllAnswered);
  };

  const handleSubmit = () => {
    window.scrollTo(0, 0);
    let formIsValid = true;
    const newErrors: Record<string, boolean> = {};

    questions.forEach((question) => {
      const answer = userAnswers[question.key] || "";
      if (!question.regex.test(answer)) {
        formIsValid = false;
        newErrors[question.key] = true;
        addAlert({
          message: `Please enter a valid ${question.title}.`,
          type: "error",
        });
      }
    });

    setErrors(newErrors);

    if (formIsValid && loggedInUser) {
      // Proceed with form submission
      const oldRequests =
        creatorProfile?.feedbackRequests ||
        ([] as unknown as FeedbackRequest[]);
      const callback = () => {
        setUserAnswers({});
        setIsAllRequiredAnswered(false);
      };
      sendFeedbackRequest(
        userAnswers as any,
        loggedInUser,
        oldRequests,
        callback
      );
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isOwner && creatorProfile) {
      addAlert({
        message:
          "Please log in with the email address where you received this link.",
        type: "error",
      });
    }
    getProfile(userId || "");
  }, [creatorProfile]);

  if (isOwner) {
    return <></>;
  }

  return (
    <Box display="flex" justifyContent="center">
      <Box
        className="get-rating-component"
        isLoading={isLoading || isFeedbackRequestLoading}
      >
        <Box className="get-rating-component-header">
          <Box marginBottom="19px">
            <Text
              color={Colors.BLACK_DARK}
              variant={TextVariants.HEADING_QUARTERNARY_FONT}
            >
              {isEmploye ? "Employer" : "Professor"}
            </Text>
            <Text
              color={Colors.ORANGE}
              variant={TextVariants.HEADING_QUARTERNARY_FONT}
              marginLeft="8px"
            >
              Rating
            </Text>
          </Box>
          <Text
            variant={TextVariants.LARGE_TERNARY_FONT}
            color={Colors.GRAY_5}
            lineHeight="22.80px"
          >
            We will send a link to your{" "}
            {isEmploye ? " previous or current employer " : " Professor "} to
            get verified rating
          </Text>
        </Box>
        <Box className="get-rating-component-content">
          {questions?.map((question, index) => (
            <Box marginBottom="20px" key={index}>
              <Text
                variant={TextVariants.MEDIUM_TERNARY_FONT_EXTRA_BOLD}
                display="block"
                className="creator-question"
                color={Colors.BLACK_DARK}
                marginBottom="15px"
              >
                {question?.title}
                <Text
                  variant={TextVariants.MEDIUM_TERNARY_FONT_EXTRA_BOLD}
                  marginLeft="3px"
                  color={Colors.ORANGE}
                  display="inline-block"
                >
                  *
                </Text>
              </Text>
              <RegularInput
                value={userAnswers[question.key] || ""}
                onChange={(e) =>
                  handleAnswerChange(e.target.value, question.key)
                }
                placeholder={question.placeHolder}
              />
            </Box>
          ))}
        </Box>
        <Box className="get-rating-component-buttons">
          <Button
            className="next-step-button"
            onClick={handleSubmit}
            width="100%"
            disabled={!isAllRequiredAnswered}
          >
            Send Link
          </Button>
          <Box marginTop="22px">
            <Button
              backgroundColor={Colors.TRANSPARENT}
              color={Colors.GRAY_2}
              className="button-medium button-full-width underline"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GetRating;
