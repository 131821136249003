import { RatingProps } from "./RatingProps";
import "./Rating.css";
import { Box } from "../box";

const filledStar = (
  <svg
    width="36"
    height="37"
    viewBox="0 0 36 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1175_3161)">
      <path
        d="M27.2872 33.0876C26.8832 33.0876 26.4812 32.9636 26.1352 32.7166L18.0002 26.8826L9.86524 32.7166C9.17224 33.2126 8.24224 33.2126 7.55324 32.7086C6.86424 32.2096 6.57424 31.3236 6.83224 30.5146L9.86624 20.7226L1.80424 15.0416C1.11924 14.5366 0.834242 13.6486 1.09624 12.8386C1.36024 12.0306 2.11224 11.4816 2.96224 11.4756L12.9472 11.4606L16.1262 1.91159C16.3942 1.10259 17.1492 0.558594 18.0002 0.558594C18.8512 0.558594 19.6062 1.10359 19.8752 1.91159L23.0002 11.4606L33.0362 11.4756C33.8892 11.4816 34.6422 12.0316 34.9032 12.8386C35.1662 13.6486 34.8812 14.5366 34.1952 15.0416L26.1332 20.7226L29.1672 30.5146C29.4272 31.3236 29.1342 32.2096 28.4472 32.7086C28.1002 32.9626 27.6942 33.0876 27.2872 33.0876Z"
        fill="#FFCB28"
      />
    </g>
    <defs>
      <clipPath id="clip0_1175_3161">
        <rect
          width="36"
          height="36"
          fill="white"
          transform="translate(0 0.558594)"
        />
      </clipPath>
    </defs>
  </svg>
);

const emptyStar = (
  <svg
    width="36"
    height="37"
    viewBox="0 0 36 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1175_3173)">
      <path
        d="M27.2872 33.0876C26.8832 33.0876 26.4812 32.9636 26.1352 32.7166L18.0002 26.8826L9.86524 32.7166C9.17224 33.2126 8.24224 33.2126 7.55324 32.7086C6.86424 32.2096 6.57424 31.3236 6.83224 30.5146L9.86624 20.7226L1.80424 15.0416C1.11924 14.5366 0.834242 13.6486 1.09624 12.8386C1.36024 12.0306 2.11224 11.4816 2.96224 11.4756L12.9472 11.4606L16.1262 1.91159C16.3942 1.10259 17.1492 0.558594 18.0002 0.558594C18.8512 0.558594 19.6062 1.10359 19.8752 1.91159L23.0002 11.4606L33.0362 11.4756C33.8892 11.4816 34.6422 12.0316 34.9032 12.8386C35.1662 13.6486 34.8812 14.5366 34.1952 15.0416L26.1332 20.7226L29.1672 30.5146C29.4272 31.3236 29.1342 32.2096 28.4472 32.7086C28.1002 32.9626 27.6942 33.0876 27.2872 33.0876Z"
        fill="#E4E4E7"
      />
    </g>
    <defs>
      <clipPath id="clip0_1175_3173">
        <rect
          width="36"
          height="36"
          fill="white"
          transform="translate(0 0.558594)"
        />
      </clipPath>
    </defs>
  </svg>
);

const Rating = ({
  totalStars,
  filledStars,
  onClick,
}: RatingProps): JSX.Element => {
  return (
    <Box className="rating-container">
      {new Array(totalStars).fill(null).map((_, i) => (
        <Box
          display="inline-block"
          cursor="pointer"
          onClick={() => onClick(filledStars === i + 1 ? 0 : i + 1)}
          key={i}
        >
          {i + 1 <= filledStars ? filledStar : emptyStar}
        </Box>
      ))}
    </Box>
  );
};

export default Rating;
