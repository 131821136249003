import { CreatorProfile } from "models";
import { Box } from "reusable";

export const ProfileBanner = (props: {
  profile: CreatorProfile | null;
}): JSX.Element => {
  if (!props.profile) {
    return <></>;
  }

  const { lookingForJob, firstName, isSubscribed, position, isHiring } =
    props.profile;

  return (
    <>
      {isSubscribed && (
        <>
          {lookingForJob && position !== "Business Owner" ? (
            <Box className="looking-for-job-banner" textAlign="center">
              {firstName} is looking for a job. Are you Hiring?
            </Box>
          ) : (
            <>
              {isHiring && position === "Business Owner" ? (
                <Box
                  className="looking-for-job-banner hiring-banner-theme"
                  textAlign="center"
                >
                  {firstName} is hiring. Are you looking for job?
                </Box>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
