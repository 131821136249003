export enum RecoilAtoms {
  LOGGED_IN_USER = "logged-in-user",
  CREATOR_PROFILE = "creator-profile",
  PROFILE_SUMMARIES = "profile-summaries",
  SINGLE_USER_SUMMARY = "single-user-summary",
  CREATOR_ANSWERS = "creator-answers",
  PROFILE_CREATION_IN_PROGRESS = "profile-creation-in-progress",
  IS_SINGLE_USER_SUMMARY_LOADING = "isSingleUserSummaryLoading",
  COMPLETE_PROFILE = "complete-profile",
  PREVIOUS_QUERY = "previousQuery",
  IS_COMPLETE_PROFILE_LOADING = "isCompleteProfileLoading",
  SHOW_LOAD_MORE_BUTTON = "showLoadMoreButton",
  IS_PROFILE_LOADING = "isProfileLoading",
  USER_LOCATION = "userLocation",
  IS_PREMIUM_MODAL_OPEN = "isPremiumModalOpen",
  OFFSET = "offset",
  LAST_FETCHED_SUMMARY = "lastFetchedSummary",
}
