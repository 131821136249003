import React, { useState, useEffect, useRef, ChangeEventHandler } from "react";
import { InputProps } from "./InputProps";
import "./Input.css"; // Ensure this is the correct path to your CSS file
import { common } from "reusable";

const Input = ({
  height,
  width,
  padding,
  borderRadius,
  border,
  placeholder,
  preIcon,
  postIcon,
  className,
  placeholderLeft,
  inputWidth,
  animate = false,
  placeholders = [],
  onChange,
  value,
  onEnter,
  onKeyDown,
  type = "text", // Default to "text", can be overridden to "password" or any other valid input type
  inputType = "text",
  ...styles
}: InputProps): JSX.Element => {
  const [animatedPlaceholder, setAnimatedPlaceholder] = useState<string>("");
  const [cursorVisible, setCursorVisible] = useState<boolean>(true);
  const [isFocused, setIsFocused] = useState<boolean>(false); // Track focus state
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (animate && placeholders.length > 0) {
      let timeoutId: NodeJS.Timeout;
      let blinkTimeoutId: NodeJS.Timeout;
      let placeholderIndex = 0;
      const typePlaceholder = () => {
        const chars = placeholders[placeholderIndex]?.split("");
        let displayText = "";
        let charIndex = 0;

        const typeNextChar = () => {
          if (charIndex < chars.length) {
            displayText += chars[charIndex];
            setAnimatedPlaceholder(displayText);
            charIndex++;
            timeoutId = setTimeout(typeNextChar, 150);
          } else if (placeholderIndex + 1 < placeholders.length) {
            placeholderIndex++;
            setTimeout(typePlaceholder, 100); // Delay before typing next placeholder
          }
        };

        typeNextChar();
      };

      typePlaceholder();

      const blinkCursor = () => {
        setCursorVisible((visible) => !visible);
        blinkTimeoutId = setTimeout(blinkCursor, 530);
      };
      blinkCursor();

      return () => {
        clearTimeout(timeoutId);
        clearTimeout(blinkTimeoutId);
      };
    }
  }, [animate, placeholders]);

  const placeholderStyle = {
    left: placeholderLeft,
  };

  const inputStyle = {
    width: inputWidth || "100%",
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === common.ENTER_KEY || e.key === "Tab") {
      e.preventDefault(); // Prevent default behavior

      if (e.key === common.ENTER_KEY && onEnter) {
        onEnter(); // Call onEnter if provided
      } else {
        // Find the next focusable element
        const focusableElements = Array.from(
          document.querySelectorAll("input, button, select, textarea, a[href]")
        ).filter((el) => el instanceof HTMLElement && el.tabIndex >= 0); // Only consider focusable elements

        const currentIndex = focusableElements.indexOf(e.currentTarget);
        if (currentIndex >= 0 && currentIndex < focusableElements.length - 1) {
          const nextElement = focusableElements[
            currentIndex + 1
          ] as HTMLElement;

          // Check if the next element is a button
          if (nextElement.tagName === "BUTTON") {
            (nextElement as HTMLButtonElement).click(); // Trigger the button click
          } else {
            nextElement.focus(); // Otherwise, just focus the element
          }
        }
      }
    }

    // Call the onKeyDown prop if provided
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  const handleFocus = () => {
    // Scroll the input into view when it receives focus
    if (inputRef.current) {
      inputRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    setIsFocused(true); // Set focus state to true
  };

  const handleBlur = () => {
    setIsFocused(false); // Set focus state to false when the input loses focus
  };

  return (
    <>
      {inputType === "textarea" ? (
        <textarea
          className="input-textarea"
          style={inputStyle}
          placeholder={placeholder as string}
          value={value}
          onChange={
            onChange as unknown as
              | ChangeEventHandler<HTMLTextAreaElement>
              | undefined
          }
        />
      ) : (
        <div className={`input-container ${className}`} style={styles}>
          {preIcon && <div className="input-icon pre">{preIcon}</div>}
          <input
            ref={inputRef}
            type={type}
            className="input-field"
            onChange={onChange}
            value={value}
            placeholder={
              isFocused
                ? "" // Hide placeholder when input is focused
                : typeof placeholder === "string"
                ? placeholder
                : animate
                ? `${animatedPlaceholder}${cursorVisible && animate ? "|" : ""}`
                : ""
            }
            style={inputStyle}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus} // Scroll into view on focus
            onBlur={handleBlur} // Update focus state on blur
          />
          {!animate &&
          typeof placeholder !== "string" &&
          !value &&
          !isFocused ? (
            <div className="input-placeholder" style={placeholderStyle}>
              {placeholder}
            </div>
          ) : null}
          {postIcon && <div className="input-icon post">{postIcon}</div>}
        </div>
      )}
    </>
  );
};

export default Input;
