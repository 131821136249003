import { useState } from "react";
import { Box } from "../box";
import "./Avatar.css";

const Avatar = (props: {
  url: string;
  height?: string;
  width?: string;
  componentKey: string;
  expand?: boolean;
  name?: string;
}): JSX.Element => {
  const { url, height, width, componentKey, expand, name } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  //functions
  const handleExpand = () => {
    if (expand) {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <>
      {url ? (
        <Box
          className={`avatar-component-parent ${
            isExpanded ? "avatar-component-parent-expanded" : ""
          }`}
          onClick={handleExpand}
        >
          <Box
            height={height}
            width={width}
            className={`avatar-component-container ${
              isExpanded ? "avatar-component-container-expanded" : ""
            }`}
            backgroundImage={`url(${url})`}
            key={componentKey}
            transition={
              !isExpanded
                ? "height 0.25s ease, width 0.25s ease, border-radius 0.25s ease, box-shadow 0.25s ease"
                : ""
            }
          ></Box>
        </Box>
      ) : (
        <Box
          isLoading={!name}
          height={height}
          width={width}
          className="default-avatar"
          background={!name ? "white" : ""}
        >
          {(name || "")[0]?.toUpperCase()}
        </Box>
      )}
    </>
  );
};

export default Avatar;
