import { NotificationService } from "../../services/notifications/NotificationService";
import { Email } from "models";
import { useState } from "react";

export function useNotifications() {
  const [isMailLoading, setMailLoading] = useState(false);

  const sendEmail = (
    email: Email,
    emailSuccessCallback?: () => void,
    errorCallback?: () => void
  ): void => {
    const handleError = () => {
      setMailLoading(false);
      if (errorCallback) {
        errorCallback();
      }
    };

    const handleSuccess = () => {
      setMailLoading(false);
      if (emailSuccessCallback) {
        emailSuccessCallback();
      }
    };
    setMailLoading(true);
    NotificationService.handleSendEmail(email, handleSuccess, handleError);
  };

  return { sendEmail, isMailLoading };
}
