import { useState, useEffect, useRef, memo } from "react";
import { Box, ImageExpand, dummyImageUrl } from "reusable";
import { useLocalStorage } from "backend";
import PhotoExpandar from "./PhotoExpandar";

const PhotoSection = (props: { urls: Array<string> }): JSX.Element => {
  //imports
  const { urls } = props;
  const { isFreshSession, isMobileScreen } = useLocalStorage();
  const urlsRef = useRef<Array<string>>([]);

  //states
  const [existingUrls, setExistingUrls] = useState<Array<string>>([""]);
  const [loadedImages, setLoadedImages] = useState<Map<string, boolean>>(
    new Map()
  );
  const [expandImageIndex, setExpandImageIndex] = useState<number>(-1);
  const [allImagesLoaded, setAllImagesLoaded] = useState<boolean>(false);

  //functions
  const updateExpandImageIndex = (index: number) => setExpandImageIndex(index);

  //effects
  useEffect(() => {
    if (
      JSON.stringify(existingUrls) !== JSON.stringify(urlsRef.current) ||
      !allImagesLoaded
    ) {
      urlsRef.current = existingUrls; // Update the ref to current URLs
      const initialMap = new Map<string, boolean>();
      existingUrls?.forEach((url) => initialMap.set(url, false));
      setLoadedImages(initialMap);

      // Load each image and update its status upon load
      existingUrls?.forEach((url) => {
        const img = new Image();
        img.src = url;
        img.onload = () => {
          setLoadedImages((prev) => new Map(prev).set(url, true));
        };
      });
    }
  }, [existingUrls]);

  useEffect(() => {
    setExistingUrls(urls);
  }, [urls]);

  useEffect(() => {
    const isAllLoaded =
      existingUrls?.length > 0 &&
      !String([...existingUrls][0])?.includes(dummyImageUrl) &&
      String([...existingUrls][0])?.length > 0 &&
      Array.from(loadedImages.values()).every((status) => status) &&
      Array.from(loadedImages.values())?.length === urls?.length &&
      !Array.from(loadedImages.keys())?.includes(dummyImageUrl);

    setAllImagesLoaded(isAllLoaded);
  }, [existingUrls, loadedImages]);

  return (
    <>
      <Box
        isLoading={urls?.includes(dummyImageUrl)}
        showSkeleton
        className="photos-section"
        verticalScroll={
          allImagesLoaded && isMobileScreen && isFreshSession ? "50%" : ""
        }
        isTemporaryScroll
      >
        <Box className="photo-column">
          {existingUrls
            ?.filter((_, index) => index % 2 === 0)
            .map((url, index) => (
              <Box key={url + index} className="creator-photo" width="100%">
                {loadedImages.get(url) ? (
                  <div className="creator-image-container">
                    <img
                      src={url}
                      alt={`Creator Image ${index}`}
                      style={{
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                      onClick={() => updateExpandImageIndex(index * 2)}
                    />
                    <Box
                      className="image-expand-icon hide-for-small-only"
                      onClick={() => updateExpandImageIndex(index * 2)}
                    >
                      <ImageExpand />
                    </Box>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "474px",
                      backgroundColor: "#e0e0e0",
                      borderRadius: "12px",
                    }}
                  ></div>
                )}
              </Box>
            ))}
        </Box>
        <Box className="photo-column">
          {existingUrls
            ?.filter((_, index) => index % 2 !== 0)
            .map((url, index) => (
              <Box key={url + index} className="creator-photo">
                {loadedImages.get(url) ? (
                  <div className="creator-image-container">
                    <img
                      src={url}
                      alt={`Creator Image ${index}`}
                      style={{
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                      onClick={() => updateExpandImageIndex(index * 2 + 1)}
                    />
                    <Box
                      className="image-expand-icon hide-for-small-only"
                      onClick={() => updateExpandImageIndex(index * 2 + 1)}
                    >
                      <ImageExpand />
                    </Box>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "363px",
                      backgroundColor: "#e0e0e0",
                      borderRadius: "12px",
                    }}
                  ></div>
                )}
              </Box>
            ))}
        </Box>
      </Box>
      {expandImageIndex > -1 && (
        <PhotoExpandar
          existingUrls={existingUrls}
          setExpandImageIndex={setExpandImageIndex}
          expandImageIndex={expandImageIndex}
        />
      )}
    </>
  );
};

export default memo(PhotoSection);
