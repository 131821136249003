// CreatorService.ts
import { FirebaseError } from "firebase/app";
import { getFriendlyErrorMessage } from "../firebase/error-mapping";
import { FirebaseService } from "../firebase/firebase-service";
import { FIRESTORE_KEYS } from "../keys";
import {
  Alert,
  CreatorProfile,
  Feedback,
  FeedbackRequest,
  ProfileSummary,
  Ratings,
} from "models";
import { FeedbackQuestions, calculateFinalRatings } from "reusable";

export const CreatorService = {
  getProfile: async (
    userId: string,
    handleResponse: (profile: CreatorProfile) => void,
    handleError: (error: Alert) => void
  ) => {
    try {
      const profileData = await FirebaseService.getFirestoreDoc(
        FIRESTORE_KEYS.CREATOR_PROFILES_COLLECTION,
        userId
      );
      if (profileData) {
        handleResponse(profileData as unknown as CreatorProfile);
      } else {
        throw new Error("Profile not found");
      }
    } catch (error) {
      handleError({
        message: getFriendlyErrorMessage(error as FirebaseError),
        type: "error",
      });
    }
  },

  getSummary: async (
    userId: string,
    handleResponse: (profile: ProfileSummary) => void,
    handleError: (error: Alert) => void
  ) => {
    const summary = await FirebaseService.getFirestoreDoc(
      FIRESTORE_KEYS.CREATOR_PROFILES_SUMMARY,
      userId
    );
    if (summary?.userId) {
      handleResponse(summary as ProfileSummary);
    } else {
      handleError({
        type: "error",
        message: "Oops something went wrong !",
      });
    }
    try {
    } catch (error) {
      handleError({
        message: getFriendlyErrorMessage(error as FirebaseError),
        type: "error",
      });
    }
  },

  searchProfileSummaries: async (
    query: string,
    handleResponse: (profile: Array<ProfileSummary>, lastProfile?: any) => void,
    handleError: (error: Alert) => void,
    lastDoc?: any,
    limit?: number,
    lat?: number,
    long?: number,
    radius?: number
  ) => {
    try {
      const response = await FirebaseService.searchProfiles(
        FIRESTORE_KEYS.CREATOR_PROFILES_SUMMARY,
        limit || 6,
        lastDoc || null,
        Boolean(query === "nearby" && lat && long),
        lat,
        long,
        radius || 6400,
        query === "all" ? undefined : query
      );
      const profiles = (response as any)?.data;
      const latestLastDoc = (response as any)?.lastDoc;
      handleResponse(profiles as unknown as ProfileSummary[], latestLastDoc);
    } catch (error) {
      handleError({
        message: getFriendlyErrorMessage(error as FirebaseError),
        type: "error",
      });
    }
  },

  giveFeedback: async (
    feedback: Feedback,
    userOldRatings: Record<string, number>,
    handleResponse: () => void,
    handleError: (error: Alert) => void
  ) => {
    try {
      const { by: userId, to: profileId } = feedback;
      const docId = userId + "_" + profileId;

      const updateFeedback = await FirebaseService.setFirestoreDoc(
        FIRESTORE_KEYS.FEEDBACKS,
        docId,
        feedback
      );

      // TODO
      // Take oldRatings from param
      let oldRatings = (
        (await FirebaseService.getFirestoreDoc(
          FIRESTORE_KEYS.CREATOR_PROFILES_SUMMARY,
          profileId
        )) as ProfileSummary
      )?.ratings as any;

      if (((userOldRatings?.design as number) || 0) > 0) {
        const totalRatings = { ...oldRatings }?.totalRatings || (0 as any);
        const divider = (totalRatings > 1 ? totalRatings : 2) - 1;
        for (const key in oldRatings) {
          if (oldRatings[key] !== "totalRatings") {
            oldRatings[key] =
              ((oldRatings[key] as number) * totalRatings -
                userOldRatings[key]) /
              divider;
          }
        }
        oldRatings.totalRatings = totalRatings - 1;
      }

      const updateSummary = await FirebaseService.updateFirestoreDoc(
        FIRESTORE_KEYS.CREATOR_PROFILES_SUMMARY,
        profileId,
        {
          ratings: calculateFinalRatings(
            FeedbackQuestions,
            feedback?.ratings as [],
            oldRatings ?? {}
          ),
        }
      );

      const updateProfile = await FirebaseService.updateFirestoreDoc(
        FIRESTORE_KEYS.CREATOR_PROFILES_COLLECTION,
        profileId,
        {
          ratings: calculateFinalRatings(
            FeedbackQuestions,
            feedback?.ratings as [],
            oldRatings ?? {}
          ),
        }
      );

      if (
        updateFeedback?.type !== "error" &&
        updateProfile?.type !== "error" &&
        updateSummary?.type !== "error"
      ) {
        handleResponse();
      } else {
        handleError({
          message: getFriendlyErrorMessage(""),
          type: "error",
        });
      }
    } catch (error) {
      handleError({
        message: getFriendlyErrorMessage(error as FirebaseError),
        type: "error",
      });
    }
  },

  getFeedback: async (
    userId: string,
    profileId: string,
    handleResponse: (ratings: Feedback) => void,
    handleError: (error: Alert, display?: boolean) => void
  ) => {
    try {
      const docId = userId + "_" + profileId;
      const feedback = ((await FirebaseService.getFirestoreDoc(
        FIRESTORE_KEYS.FEEDBACKS,
        docId
      )) || {}) as Feedback;

      if ((feedback as any)?.type !== "error") {
        handleResponse(feedback);
      } else {
        handleError(
          {
            message: getFriendlyErrorMessage(""),
            type: "error",
          },
          false
        );
      }
    } catch (error) {
      handleError(
        {
          message: getFriendlyErrorMessage(error as FirebaseError),
          type: "error",
        },
        true
      );
    }
  },

  sendFeedbackRequest: async (
    requestDetails: {
      emailId: string;
      companyName?: string;
      employerName: string;
      universityName?: string;
      professorName?: string;
    },
    userId: string,
    oldRequests: Array<FeedbackRequest>,
    handleResponse: () => void,
    handleError: (error: Alert) => void
  ) => {
    try {
      if (!requestDetails?.emailId || !userId) {
        handleError({
          message: getFriendlyErrorMessage(""),
          type: "error",
        });
        return;
      }
      const updateFeedback = await FirebaseService.updateFirestoreDoc(
        FIRESTORE_KEYS.CREATOR_PROFILES_COLLECTION,
        userId,
        {
          feedbackRequests: [...oldRequests, requestDetails],
        }
      );

      if (!updateFeedback?.type) {
        handleResponse();
      } else {
        handleError({
          message: getFriendlyErrorMessage(""),
          type: "error",
        });
      }
    } catch (error) {
      handleError({
        message: getFriendlyErrorMessage(error as FirebaseError),
        type: "error",
      });
    }
  },
};
