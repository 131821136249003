import React, { useState, useEffect } from "react";
import { Button, Dropdown } from "reusable"; // Assuming Button is imported from a reusable component library
import "./ShareProfile.css"; // Import the CSS file
import { useAlerts } from "backend";

const ShareProfile: React.FC<{
  profileUrl: string;
  isPreview: boolean;
  userName: string;
  title: string;
}> = ({ profileUrl, userName, title }) => {
  const { addAlert } = useAlerts();
  const shareMessage = `Discover the amazing work of ${userName}, a talented interior designer/architect! Check out their stunning portfolio and innovative designs on kydoscope!`;

  const shareOnWhatsApp = () => {
    const url = encodeURIComponent(profileUrl);
    const text = encodeURIComponent(shareMessage);
    window.open(
      `https://api.whatsapp.com/send?text=${text}%20${url}`,
      "_blank"
    );
  };

  const shareOnFacebook = () => {
    const url = encodeURIComponent(profileUrl);
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      "_blank"
    );
  };

  const copyUrlToClipboard = () => {
    navigator.clipboard.writeText(profileUrl);
    addAlert({
      type: "success",
      message: "Link copied to clipboard.",
    });
  };

  const menuOptions = [
    {
      title: "Copy URL",
      onClick: copyUrlToClipboard,
    },
    {
      title: "WhatsApp",
      onClick: shareOnWhatsApp,
    },
    {
      title: "Facebook",
      onClick: shareOnFacebook,
    },
  ];

  return (
    <Dropdown
      title={title}
      options={menuOptions as unknown as Array<Record<string, string | void>>}
      crossWidth="87.74px"
    />
  );
};

export default ShareProfile;
