import { Appsettings } from "config";
import { Box, Colors, Text, TextVariants } from "reusable";

const AboutUs = (): JSX.Element => {
  return (
    <Box className="document-component">
      <Box className="document-component-wrapper">
        <Box width="100%" textAlign="center">
          <Text variant={TextVariants.HEADING_QUARTERNARY_FONT}>
            <Text
              variant={TextVariants.HEADING_QUARTERNARY_FONT}
              color={Colors.ORANGE}
              marginRight="8px"
            >
              {Appsettings.appName}
            </Text>
            About Us
          </Text>
        </Box>

        <div className="document-content">
          We’re two passionate designers who teamed up to create a platform
          that’s as unique as you are. Our name, Kydoscope, is a blend of “KYD”
          (Know Your Designer) and “scope,” reflecting our mission to help you
          showcase your creativity and broaden your professional horizon.
          <br />
          <br />
          We know how important it is for architects and interior designers to
          stand out and connect with clients. That’s why we’ve crafted a page
          builder that makes it effortless for you to create stunning,
          personalized landing pages. Whether you’re showing off your latest
          project or sharing your design journey, Kydoscope is here to make it
          both fun and effective.
          <br />
          <br />
          But we’re more than just a platform. We’re building a community where
          Kydos can come together, share ideas, inspire each other, and find
          exciting opportunities. It’s a space where your talent gets the
          spotlight it deserves and where collaboration is at the heart of
          everything we do.
          <br />
          <br />
          If you share our passion for design and creativity, we’d love to hear
          from you! Feel free to reach out to us anytime at{" "}
          <a style={{ color: Colors.ORANGE }} href="mailto:info@kydoscope.com">
            info@kydoscope.com
          </a>
          <br />
          <br />
          We’re always here to listen and support you on your design journey.
        </div>
      </Box>
    </Box>
  );
};

export default AboutUs;
