export enum TextVariants {
  MEDIUM_PRIMARY_FONT_BOLD = "medium-primary-font-bold",
  MEDIUM_PRIMARY_FONT = "medium-primary-font",
  SMALL_PRIMARY_FONT = "small-primary-font",
  LARGE_SECONDARY_FONT = "large-secondary-font",
  MEDIUM_TERNARY_FONT_BOLD = "medium-ternary-font-bold",
  MEDIUM_TERNARY_FONT_EXTRA_BOLD = "medium-ternary-font-extra-bold",
  MEDIUM_15_TERNARY_FONT_EXTRA_BOLD = "medium-15-ternary-font-extra-bold",
  MEDIUM_15_TERNARY_FONT_BOLD = "medium-15-ternary-font-bold",
  EXTRA_LARGE_TERNARY_FONT = "extra-large-ternary-font",
  EXTRA_LARGE_TERNARY_FONT_BOLD = "extra-large-ternary-font-bold",
  LARGE_TERNARY_FONT_BOLD = "large-ternary-font-bold",
  LARGE_TERNARY_FONT_BOLD_600 = "large-ternary-font-bold-600",
  HEADING_TERNARY_FONT = "heading-ternary-font",
  HEADING_TERNARY_FONT_EXTRA_BOLD = "heading-ternary-font-extra-bold",
  HEADING_TERNARY_FONT_600_BOLD = "heading-ternary-font-600-bold",
  SMALL_TERNARY_FONT_BOLD_UNDERLINE = "small-ternary-font-bold-underline",
  SMALL_TERNARY_FONT_BOLD = "small-ternary-font-bold",
  LARGE_TERNARY_FONT = "large-ternary-font",
  EXTRA_LARGE_TERNARY_FONT_EXTRA_BOLD = "extra-large-ternary-font-extra-bold",
  DOUBLE_LARGE_TERNARY_FONT_BOLD = "double-large-ternary-font-bold",
  DOUBLE_LARGE_TERNARY_FONT = "double-large-ternary-font",
  MOBILE_HEADER = "mobile-heading",
  HEADING_QUARTERNARY_FONT = "heading-quaternary-font",
  HEADING_SECONDARY = "heading-secondary",
  HEADING_EXTRA_LARGE = "heading-extra-large",
  LARGEST_HEADER = "largest-header",
}
