export const worldLanguages = [
  // Indian languages
  "Hindi",
  "Bengali",
  "Telugu",
  "Marathi",
  "Tamil",
  "Gujarati",
  "Kannada",
  "Odia",
  "Malayalam",
  "Punjabi",
  "Assamese",
  "Urdu",
  "Maithili",
  "Sanskrit",
  "Santali",
  "Kashmiri",
  "Konkani",
  "Nepali",
  "Sindhi",
  "Dogri",
  "Manipuri",
  "Bodo",

  // Other common world languages
  "Mandarin Chinese",
  "Spanish",
  "English",
  "Arabic",
  "Portuguese",
  "Russian",
  "Japanese",
  "French",
  "German",
  "Javanese",
  "Korean",
  "Vietnamese",
  "Italian",
  "Turkish",
  "Persian",
  "Polish",
  "Dutch",
  "Thai",
  "Greek",
  "Czech",
  "Swedish",
  "Hungarian",
  "Hebrew",
  "Danish",
  "Finnish",
  "Norwegian",
];
