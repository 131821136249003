import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  //  sendEmailVerification,
  User,
} from "firebase/auth";
import { UserService } from "../../services/user/user-service";
import { loggedInUserAtom } from "../../data/user-atoms";
import {
  creatorProfileAtom,
  isProfileCreationInProgressAtom,
  isProfileLoadingAtom,
} from "../../data/creator-atoms";
import { Alert, CreatorProfile, LoggedInUser } from "models";
import { USER_ROLES, capitalize } from "reusable";
import { useAlerts } from "../alert/use-alert";
import {
  Appsettings,
  // auth,
  firestoreTimestamp,
} from "config";

export const useUser = () => {
  const { addAlert } = useAlerts();
  const [loggedInUser, setLoggedInUser] = useRecoilState<LoggedInUser | null>(
    loggedInUserAtom
  );
  const [isPremiumUser, setIsPremiumUser] = useState<boolean>(false);
  const [uploadPhotoLimit, setUploadPhotoLimit] = useState<number>(
    Appsettings.nonPremiumUploadPhotoLimit
  );
  const [authUser, setAuthUser] = useState<User | null>();
  const [creatorProfile, setCreatorProfile] =
    useRecoilState<CreatorProfile | null>(creatorProfileAtom);
  const [isLoading, setIsLoading] =
    useRecoilState<boolean>(isProfileLoadingAtom);
  const [isProfileCreationInProgress, setIsProfileCreationInProgress] =
    useRecoilState<boolean>(isProfileCreationInProgressAtom);

  const handleError = (alert: Alert) => {
    addAlert(alert);
    setIsLoading(false);
  };

  const addSuccessAlert = (message: string) =>
    addAlert({
      type: "success",
      message: message,
    });

  const signUpUser = (email: string, password: string, name: string) => {
    setIsLoading(true);
    const onSuccess = (user: User) => {
      // sendEmailVerification(auth.currentUser as User)
      //   .then(() => {
      //     console.log("email sent");
      //   })
      //   .catch((e) => console.log(e));
      UserService.getLoggedInUser(user?.uid, setLoggedInUser, alert);
      setIsLoading(false);
      addSuccessAlert(
        `Welcome to ${Appsettings.appName}, ${user?.displayName} ! account created successfully.`
      );
    };

    const initialUserData = {
      name: capitalize(name),
      email: email,
      profilePhotoUrl: "",
      signUpMethod: "custom",
      role: USER_ROLES.VIEWER,
      isCreatorQuestionsAnswered: false,
    };

    UserService.signUpUser(
      email,
      password,
      name,
      onSuccess,
      handleError,
      initialUserData
    );
  };

  const signUpWithGoogle = () => {
    setIsLoading(true);
    const onSuccess = (user: User, isNew: boolean) => {
      addSuccessAlert(
        isNew
          ? `Welcome to ${Appsettings.appName}, ${user?.displayName} !`
          : `Welcome back ${user?.displayName} !`
      );
      UserService.getLoggedInUser(user?.uid, setLoggedInUser, alert);
      setIsLoading(false);
    };

    const initialUserData = {
      profilePhotoUrl: "",
      signUpMethod: "google",
      role: USER_ROLES.VIEWER,
      isCreatorQuestionsAnswered: false,
    };

    UserService.signInWithGoogle(onSuccess, handleError, initialUserData);
  };

  const loginUser = (email: string, password: string) => {
    setIsLoading(true);

    const onSuccess = (user: User) => {
      setIsLoading(false);
      UserService.getLoggedInUser(user?.uid, setLoggedInUser, alert);
      addSuccessAlert(`Welcome back ${user?.displayName} !`);
    };

    UserService.loginUser(email, password, onSuccess, handleError);
  };

  const subscribeUser = () => {
    UserService.subscribeToAuthChanges((user: User | null) => {
      setAuthUser(user);
      if (user) {
        setIsLoading(true);
        const handleResponse = (user: LoggedInUser) => {
          setLoggedInUser(user);
          setIsLoading(false);
        };

        const handleError = (message: any) => {
          addAlert(message);
          setIsLoading(false);
        };
        UserService.getLoggedInUser(user?.uid, handleResponse, handleError);
      }
    });
  };

  const publishProfile = (
    profileDetails: CreatorProfile,
    callback: (userId: string) => void,
    editing?: boolean
  ) => {
    setIsProfileCreationInProgress(true);
    const handlePublishProfileError = (alert: Alert) => {
      handleError(alert);
      setIsProfileCreationInProgress(false);
    };
    const handleSuccess = (profile: CreatorProfile) => {
      if (editing) {
        setCreatorProfile({ ...creatorProfile, ...profile });
      }

      setLoggedInUser({
        ...loggedInUser,
        isCreatorQuestionsAnswered: true,
        profilePicture: profile?.profilePicture,
      } as LoggedInUser);
      callback(profile?.userId);
      setIsProfileCreationInProgress(false);
      addSuccessAlert(
        editing
          ? "Profile Updated !"
          : `All set, ${profile?.name} ! Profile Created !`
      );
    };
    UserService.publishUserProfile(
      {
        ...profileDetails,
        userId: loggedInUser?.userId || "",
        creationTime: firestoreTimestamp,
      },
      handleSuccess,
      handlePublishProfileError,
      editing
    );
  };

  const logout = () => {
    const onSuccess = () => {
      window.localStorage.clear();
      window.location.href = "/";
    };
    UserService.logout(onSuccess, handleError);
  };

  const refreshLoggedInUser = () => {
    if (loggedInUser) {
      setIsLoading(true);
      const handleResponse = (user: LoggedInUser) => {
        setLoggedInUser(user);
        setIsLoading(false);
      };

      const handleError = (message: any) => {
        addAlert(message);
        setIsLoading(false);
      };
      UserService.getLoggedInUser(
        loggedInUser?.userId,
        handleResponse,
        handleError
      );
    }
  };

  useEffect(() => {
    const isPremium = loggedInUser?.subscription?.active;
    setIsPremiumUser(Boolean(isPremium));
    setUploadPhotoLimit(
      isPremium
        ? Appsettings.premiumUploadPhotoLimit
        : Appsettings.nonPremiumUploadPhotoLimit
    );
  }, [loggedInUser]);

  return {
    loggedInUser,
    signUpUser,
    loginUser,
    subscribeUser,
    publishProfile,
    isLoading,
    signUpWithGoogle,
    authUser,
    logout,
    isProfileCreationInProgress,
    isPremiumUser,
    uploadPhotoLimit,
    refreshLoggedInUser,
  };
};
