import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./PageTransition.css";

const PageTransition = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    setIsTransitioning(true);

    const timer = setTimeout(() => {
      setIsTransitioning(false);
    }, 300); // Duration of the transition

    return () => clearTimeout(timer);
  }, [location.pathname]);

  return (
    <div
      className={`page-transition ${isTransitioning ? "transitioning" : ""}`}
    >
      {children}
    </div>
  );
};

export default PageTransition;
