import React, { useEffect } from "react";

interface GoogleScriptProps {
  apiKey: string;
  libraries?: string[];
  onLoad: () => void;
}

const GoogleScript: React.FC<GoogleScriptProps> = ({
  apiKey,
  libraries = ["places"],
  onLoad,
}) => {
  useEffect(() => {
    // Check if Google Maps script is already loaded
    if (window.google && window.google.maps) {
      onLoad();
      return;
    }

    // Create a new script element for Google Maps API
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libraries.join(
      ","
    )}`;
    script.async = true; // Load the script asynchronously
    script.defer = true; // Defer execution until the document is parsed
    script.onload = () => {
      onLoad(); // Call the callback after the script is loaded
    };
    script.onerror = () => {
      console.error("Failed to load Google Maps script");
    };

    // Append the script to the document head
    document.head.appendChild(script);

    // Clean up by removing the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, [apiKey, libraries, onLoad]);

  return null;
};

export default GoogleScript;
