// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { doc, onSnapshot, Timestamp } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { ref, onValue } from "firebase/database";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyDacS-o3evxIZ1oqVoC9My7BuPS_g4i61I",
  authDomain: "kydoscopee.firebaseapp.com",
  projectId: "kydoscopee",
  storageBucket: "kydoscopee.appspot.com",
  messagingSenderId: "20337265409",
  appId: "1:20337265409:web:a2d1c2709061c1ef742900",
  measurementId: "G-BVNLY1C4C2",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const provider = new GoogleAuthProvider();
const functions = getFunctions(app);
// Convert milliseconds to Firestore Timestamp
const firestoreTimestamp = Timestamp.fromMillis(Date.now());

export {
  auth,
  db,
  storage,
  provider,
  onValue,
  ref,
  functions,
  httpsCallable,
  doc,
  onSnapshot,
  firestoreTimestamp,
};
