import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser, useModal } from "backend";

import {
  Box,
  CreatorQuestions as questions,
  TextVariants,
  Colors,
  Text,
  Button,
  CreatorQuestionIds,
  LOCAL_STORAGE_KEYS,
  capitalize,
} from "reusable";
import { Question, Step } from "../sign-up/Question";

import RenderInputField from "../sign-up/components/RenderInputField";
import "./CreatorQuestions.css";
import { CompleteProfile, CreatorProfile } from "models";

const CreatorQuestions = (props: {
  step: number;
  setStep: (difference: number) => void;
  setCreatorAnswers: (creatorProfile: CompleteProfile) => void;
  creatorAnswers: CreatorProfile;
}): JSX.Element => {
  const { step, setStep, creatorAnswers, setCreatorAnswers } = props;
  const creatorQuestionJson = questions as unknown as Array<Step>;
  const { isLoading, loggedInUser, isPremiumUser } = useUser();
  const { setIsPremiumModalOpen } = useModal();
  const goTo = useNavigate();

  //states
  const [isAllRequiredAnswered, setIsAllRequiredAnswered] =
    useState<boolean>(false);

  //functions
  const handleAnswer = async (
    event: ChangeEvent<HTMLInputElement>,
    answeredQuestion: Question
  ) => {
    const { questionID, type } = answeredQuestion;
    const answer = event.target.value;
    const target = event.target;

    if (type === "checkbox") {
      const currentAnswers =
        (creatorAnswers as any)[questionID]?.split(", ") ?? [];
      const multiple = Boolean((event as any)?.multiple);
      const checked = (target as HTMLInputElement).checked
        ? (multiple ? [...currentAnswers, answer] : [answer])
            ?.filter((a) => a !== "")
            .join(", ")
        : currentAnswers.filter((a: string) => a !== answer).join(", ");

      setCreatorAnswers({ ...creatorAnswers, [questionID]: checked });
    } else if (type === "photo") {
      const existingFiles = (target as HTMLInputElement)?.files || [];
      const newFiles =
        [
          ...(creatorAnswers?.newCreatorImages || []),
          ...((target as any)?.newCreatorImages || []),
        ] || [];
      const newProfilePicture =
        (target as any)?.newProfilePicture ||
        creatorAnswers?.newProfilePicture ||
        [];
      const removedFiles = [
        ...(creatorAnswers?.removedUrls || []),
        (target as any)?.removedUrl || "",
      ];
      const updatedFiles = Array.from(existingFiles || [])?.filter(
        (url: any) => url !== ((target as any)?.removedUrl as string)
      );

      setCreatorAnswers({
        ...creatorAnswers,
        [questionID]: [...updatedFiles],
        removedUrls: [...removedFiles],
        newCreatorImages: [...newFiles],
        newProfilePicture: [...newProfilePicture],
      });
    } else if (type === "switch") {
      setCreatorAnswers({
        ...creatorAnswers,
        [questionID]: answer,
      });
    } else if (type === "theme") {
      const selectedTheme = (event as any)?.theme || {};
      if (selectedTheme?.background?.length) {
        setCreatorAnswers({
          ...creatorAnswers,
          [questionID]: selectedTheme,
        });
      }
    } else {
      setCreatorAnswers({
        ...creatorAnswers,
        [questionID]:
          questionID === "contactNumber" ? answer?.slice(0, 10) : answer,
        name: capitalize(
          `${creatorAnswers?.firstName} ${creatorAnswers?.lastName}`
        ),
      });
    }
    if (
      loggedInUser &&
      !loggedInUser?.isCreatorQuestionsAnswered &&
      questionID !== CreatorQuestionIds.CREATOR_PHOTOS &&
      questionID !== CreatorQuestionIds.PROFILE_PICTURE
    ) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.CREATOR_PROFILE,
        JSON.stringify({
          ...creatorAnswers,
          [questionID]: answer,
        })
      );
    }
  };

  const handleNextStep = () => {
    window.scrollTo(0, 0);
    setStep(1);
  };

  const currentStep = creatorQuestionJson[step - 1];

  const businessOwnerQuestions = [
    "occupation",
    "position",
    "firstName",
    "lastName",
    "contactNumber",
    "location",
    "profilePicture",
    "about",
    "languages",
    "experienceYears",
    "completedProjects",
    "projectCost",
    "projectType",
    "creatorPhotos",
    "companyName",
  ];

  const businessPremiumQuestions = [
    "websiteUrl",
    "instagramLink",
    "isHiring",
    "theme",
  ];

  const finalBusinessQuestions = [
    ...businessOwnerQuestions,
    ...(creatorAnswers?.isSubscribed ? businessPremiumQuestions : []),
  ];

  const nonBusinessQuestions = [
    "occupation",
    "position",
    "firstName",
    "lastName",
    "contactNumber",
    "location",
    "profilePicture",
    "about",
    "languages",
    "experienceYears",
    "companyName",
    "graduationYear",
    "softwareSkills",
    "creatorPhotos",
    "universityName",
  ];

  const nonBusinessPremiumQuestions = [
    "websiteUrl",
    "instagramLink",
    "lookingForJob",
    "theme",
  ];

  const finalNonBusinessQuestions = [
    ...nonBusinessQuestions,
    ...(creatorAnswers?.isSubscribed ? nonBusinessPremiumQuestions : []),
  ];

  useEffect(() => {
    if (creatorAnswers) {
      const requiredQuestions = currentStep.questions
        .filter(
          (d) =>
            (creatorAnswers?.position?.includes("Business Owner")
              ? finalBusinessQuestions.includes(d.questionID)
              : finalNonBusinessQuestions.includes(d.questionID)) &&
            (d?.roles?.length
              ? d.roles.includes(creatorAnswers?.position)
              : true)
        )
        .filter((q) => q.isRequired)
        .map((q) => q.questionID);
      const isAllAnswered = requiredQuestions.every(
        (id) =>
          (creatorAnswers as any)[id] &&
          String((creatorAnswers as any)[id]).length
      );
      setIsAllRequiredAnswered(isAllAnswered);
    } else {
      setIsAllRequiredAnswered(false);
    }
  }, [creatorAnswers, currentStep]);

  const titleArray = currentStep?.stepTitle?.split(" ");
  const isFinalStep = creatorQuestionJson?.length === step;

  return (
    <Box className="creator-questions" isLoading={isLoading}>
      <Box>
        <Box className="step-info responsive-60-40" textAlign="center">
          <Text
            color={Colors.BLACK_DARK}
            variant={TextVariants.HEADING_QUARTERNARY_FONT}
          >
            {titleArray[0]}
          </Text>
          <Text
            marginLeft="7px"
            color={titleArray?.length > 2 ? Colors.BLACK_DARK : Colors.ORANGE}
            variant={TextVariants.HEADING_QUARTERNARY_FONT}
          >
            {titleArray[1]}
          </Text>

          {titleArray?.length > 2 && (
            <>
              <br />
              <Text
                marginLeft="7px"
                color={Colors.ORANGE}
                variant={TextVariants.HEADING_QUARTERNARY_FONT}
              >
                {titleArray
                  ?.filter((_, i) => i > 1)
                  ?.map((v) =>
                    v?.includes("8")
                      ? !isPremiumUser
                        ? v
                        : v.replace("8", "30")
                      : v
                  )
                  ?.join(" ")}
              </Text>
            </>
          )}
          <Text
            variant={TextVariants.MEDIUM_PRIMARY_FONT}
            color={Colors.GRAY_5}
            className="step-description"
            lineHeight="22.40px"
          >
            {currentStep?.description}
          </Text>
        </Box>
        {isFinalStep && !isPremiumUser && (
          <Box textAlign="center" width="100%" marginTop="-25px">
            <Text
              variant={TextVariants.MEDIUM_PRIMARY_FONT}
              color={Colors.GRAY_3}
              className="step-description"
              lineHeight="22.40px"
            >
              Want to Upload 30 Photos + More Benefits
            </Text>
            <br />
            <Box
              margin="15px 0 25px 0"
              display="inline-block"
              onClick={() => setIsPremiumModalOpen(true)}
            >
              <Button className="button-large-round">
                <Box
                  className="inline-block"
                  marginBottom="3px"
                  marginRight="4px"
                >
                  💎
                </Box>
                Become a Pro Member
              </Button>
            </Box>
          </Box>
        )}

        {currentStep?.questions
          .filter(
            (d) =>
              (creatorAnswers?.position?.includes("Business Owner")
                ? finalBusinessQuestions.includes(d.questionID)
                : finalNonBusinessQuestions.includes(d.questionID)) &&
              (d?.roles?.length
                ? d.roles.includes(creatorAnswers?.position)
                : true)
          )
          ?.map((question, i) => (
            <Box className="responsive-60-40" key={i}>
              <Box marginBottom="10px">
                <Text
                  variant={TextVariants.MEDIUM_TERNARY_FONT_EXTRA_BOLD}
                  display="block"
                  className="creator-question"
                  color={Colors.BLACK_DARK}
                  marginBottom="10px"
                >
                  {question?.content}
                  {!isFinalStep && (
                    <Text
                      variant={TextVariants.MEDIUM_TERNARY_FONT_EXTRA_BOLD}
                      marginLeft="3px"
                      color={Colors.ORANGE}
                      display={question.isRequired ? "inline-block" : "none"}
                    >
                      *
                    </Text>
                  )}
                </Text>
                {question?.subContent && (
                  <Text
                    variant={TextVariants.LARGE_TERNARY_FONT}
                    color={Colors.GRAY_1}
                    className="creator-sub-question"
                  >
                    {question?.subContent}
                  </Text>
                )}
              </Box>
              <RenderInputField
                value={(creatorAnswers as any)[question?.questionID]}
                onChange={handleAnswer}
                question={question}
              />
            </Box>
          ))}
        <Box
          className="grid-x"
          scrollToViewPort={isFinalStep && isAllRequiredAnswered}
        >
          <Box marginRight="12px">
            <Button
              disabled={step === 1}
              onClick={() => {
                setStep(-1);
                window.scrollTo(0, 0);
              }}
              className="cell button-medium shrink"
            >
              Back
            </Button>
          </Box>
          <Button
            onClick={handleNextStep}
            className="cell next-step-button responsive-60-40 auto"
            disabled={!isAllRequiredAnswered}
          >
            {isFinalStep
              ? loggedInUser?.isCreatorQuestionsAnswered
                ? "Update Profile"
                : "Create Profile"
              : "Continue"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreatorQuestions;
