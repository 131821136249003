import React, { useEffect, forwardRef, useRef, MutableRefObject } from "react";
import { BoxProps } from "./BoxProps";

// Utility function to check if an element is in the viewport
const isInViewport = (element: HTMLElement): boolean => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

const Box = forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  const {
    children = <></>,
    className = "",
    id,
    onClick,
    isLoading,
    showSkeleton,
    verticalScroll,
    isTemporaryScroll,
    scrollToViewPort,
    tabIndex,
    key,
    ...styles
  } = props;

  // Use an internal ref if no ref is provided
  const internalRef = useRef<HTMLDivElement>(null);
  const boxRef = (ref as MutableRefObject<HTMLDivElement>) || internalRef;

  useEffect(() => {
    if (verticalScroll !== undefined && verticalScroll !== "") {
      const scrollPosition =
        typeof verticalScroll === "number"
          ? verticalScroll
          : verticalScroll.endsWith("%")
          ? (window.innerHeight * parseFloat(verticalScroll)) / 100
          : parseFloat(verticalScroll);

      const scrollToPosition = () => {
        window.scrollTo({ top: scrollPosition, behavior: "smooth" });
      };

      const scrollBack = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      };

      const animateScroll = () => {
        scrollToPosition();
        setTimeout(scrollBack, 1500); // Adjust the duration as needed
      };

      if (isTemporaryScroll) {
        animateScroll();
      }
    }
  }, [verticalScroll, isTemporaryScroll]);

  useEffect(() => {
    if (scrollToViewPort) {
      const element = boxRef.current;

      if (element && !isInViewport(element)) {
        element.scrollIntoView({ behavior: "smooth", block: "end" });
        setTimeout(() => window.scrollBy(0, 50), 500); // Adjust to scroll 20px from the bottom with smooth animation
      }
    }
  }, [scrollToViewPort, boxRef]);

  return (
    <div
      onClick={onClick}
      className={`${className} ${
        isLoading && showSkeleton
          ? "loader skeleton"
          : isLoading
          ? "loader"
          : ""
      }`}
      ref={boxRef}
      key={key}
      style={styles}
      tabIndex={tabIndex}
    >
      {children}
    </div>
  );
});

Box.displayName = "Box";

export default Box;
