import React, { ChangeEvent } from "react";

import { CheckboxProps } from "./CheckboxProps";
import "./Checkbox.css";
import { CheckedBox, UncheckedBox } from "reusable";

const Checkbox: React.FC<CheckboxProps> = ({
  height = "52px",
  width = "400px",
  checkedValues,
  onChange,
  options,
  multiple = false,
  ...styles
}) => {
  return (
    <>
      {options.map((option) => (
        <label className="checkbox-container" key={option}>
          <input
            type="checkbox"
            checked={checkedValues.includes(option)}
            onChange={(e) =>
              onChange({
                ...e,
                multiple: multiple,
              } as ChangeEvent<HTMLInputElement>)
            }
            style={styles}
            className="checkbox-input"
            value={option}
          />
          <span className="un-checked-box">
            <CheckedBox />
          </span>
          <span className="checked-box">
            <UncheckedBox />
          </span>
          <span className="checkbox-label">{option}</span>
        </label>
      ))}
    </>
  );
};

export default Checkbox;
