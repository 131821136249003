import React, { useState, useRef, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Colors,
  Cross,
  EmptyProfilePicture,
  Text,
  TextVariants,
  compressImage,
} from "reusable";
import "./UploadPhoto.css";
import { useUser, useModal } from "backend";

const ProfilePhotoUploader = (props: {
  onImageChange: (e: any) => void;
  type: "profile-photo" | "creator-photos";
  existingImages: FileList | string[] | string;
  // accepts both image url or uploaded file.
}) => {
  const { onImageChange, type, existingImages } = props;
  const { uploadPhotoLimit } = useUser();
  const { setIsPremiumModalOpen } = useModal();
  //------------------------ profile picture logic start -----------------------------------
  const [image, setImage] = useState<string | null>(null);
  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const filePromises = Array.from(event.target.files).map((file) =>
        compressImage(file)
      );
      const files = await Promise.all(filePromises); // Wait for all promises to resolve

      const file = files[0];
      const url = URL.createObjectURL(file);
      setImage(url);

      onImageChange({
        target: {
          files: [url],
          newProfilePicture: files,
          value: "",
        },
      });
    }
  };

  const handleDeleteImage = () => {
    setImage(null);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    onImageChange({ target: { files: [], newProfilePicture: [], value: "" } });
  };

  //------------------------ profile picture logic end -----------------------------------

  //------------------------ creator images logic start -----------------------------------
  const [creatorImageUrls, setCreatorImageUrls] = useState<Array<string>>([]);

  const inputRef = useRef<HTMLInputElement>(null);
  const handleCreatorImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const filePromises = Array.from(event.target.files).map((file) =>
        compressImage(file)
      );
      const files = await Promise.all(filePromises); // Wait for all promises to resolve
      const urls = Array.from(files).map((file) => URL.createObjectURL(file));
      let filledUrls = [...urls];
      while (filledUrls.length < uploadPhotoLimit) {
        filledUrls.push("");
      }
      const finalFiles = [
        ...(Array.from(existingImages || ([] as any)) as string[]),
        ...urls,
      ];
      if (finalFiles?.length > uploadPhotoLimit) {
        setIsPremiumModalOpen(true);
      }
      setCreatorImageUrls(filledUrls);
      if (finalFiles?.length > uploadPhotoLimit) {
      }
      onImageChange({
        target: {
          newCreatorImages: Array.from(files)?.filter(
            (_, i) => i < uploadPhotoLimit - (existingImages?.length || 0)
          ),
          value: "",
          files: finalFiles?.filter((_, i) => i < uploadPhotoLimit),
        },
      });
    }
  };

  const removePhotoHandler = (removedIndex: number) => {
    onImageChange({
      target: {
        value: [],
        removedUrl: (Array.from(existingImages as any) || [])[removedIndex],
        files: existingImages,
      },
    });
  };

  //------------------------ creator images logic end -----------------------------------

  //------------------------common logic start -----------------------------------

  const convertFilesToUrls = async (files: any) => {
    const urls = Array.from(files).map((file: any) =>
      !new URL(String(file)) ? URL.createObjectURL(file) : file
    );
    return urls;
  };

  useEffect(() => {
    const initializeImages = () => {
      const images =
        typeof existingImages === "string"
          ? existingImages
          : [...(existingImages as string[])];
      if (type === "profile-photo") {
        setImage((images as unknown as string) || null);
      } else if (type === "creator-photos") {
        while (images?.length < uploadPhotoLimit) {
          (images as string[])?.push("");
        }
        setCreatorImageUrls(images as string[]);
      }
    };

    if (existingImages) initializeImages();
  }, [existingImages, type, uploadPhotoLimit]);

  //------------------------ creator images logic end -----------------------------------

  return (
    <>
      {type === "profile-photo" ? (
        <Box className="upload-profile-photo-container">
          <Box display="inline-block" marginRight="20px">
            {image ? (
              <Avatar
                height="62px"
                width="62px"
                url={image ?? ""}
                componentKey="profile-photo-image"
              />
            ) : (
              <EmptyProfilePicture />
            )}
          </Box>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            ref={inputRef}
            id="fileInput"
            onChange={handleImageChange}
          />
          <Box display="inline-block">
            {image ? (
              <>
                <Box display="inline-block" marginRight="20px">
                  <Button
                    className="upload-new-picture-button"
                    onClick={() =>
                      document.getElementById("fileInput")!.click()
                    }
                  >
                    Upload Picture
                  </Button>
                </Box>
                <Box
                  display="inline-block"
                  className="profile-picture-delete-button"
                >
                  <Button
                    className="profile-picture-delete-button"
                    onClick={handleDeleteImage}
                  >
                    Delete
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Box display="inline-block" marginRight="20px">
                  <Button
                    className="upload-picture-button"
                    onClick={() =>
                      document.getElementById("fileInput")!.click()
                    }
                  >
                    Upload Picture
                  </Button>
                </Box>
                <Text
                  variant={TextVariants.MEDIUM_PRIMARY_FONT_BOLD}
                  color={Colors.GRAY_2}
                >
                  No file chosen
                </Text>
              </>
            )}
          </Box>
        </Box>
      ) : (
        <>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            ref={inputRef}
            id="fileInput"
            onChange={handleCreatorImageChange}
            multiple
            max={8}
          />
          {creatorImageUrls?.length ? (
            <>
              <Box className="creator-photos-preview-container">
                {creatorImageUrls?.map((url, i) => (
                  <Box
                    key={i}
                    className={
                      url
                        ? "preview-photo"
                        : "preview-photo empty-preview-photo"
                    }
                    backgroundImage={`url(${url})`}
                  >
                    {url ? (
                      <Box
                        className="remove-photo"
                        onClick={() => removePhotoHandler(i)}
                      >
                        <Cross />
                      </Box>
                    ) : (
                      <Box
                        className="add-photo"
                        onClick={() =>
                          document.getElementById("fileInput")!.click()
                        }
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 7V10M10 10V13M10 10H13M10 10H7M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
                            stroke="#E5E7EB"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
              <Box textAlign="center" width="100%">
                <Text
                  variant={TextVariants.MEDIUM_PRIMARY_FONT}
                  color={Colors.GRAY_5}
                  className="step-description"
                  lineHeight="17px"
                >
                  We do not allow uploading 3D renders to maintain <br />
                  authenticity of the profile.
                </Text>
              </Box>
            </>
          ) : (
            <Box className="drag-creator-photo-box">
              <Text
                variant={TextVariants.LARGE_TERNARY_FONT_BOLD}
                color={Colors.BLACK_DARK}
              >
                Drop your files here or
              </Text>
              <Box
                onClick={() => document.getElementById("fileInput")!.click()}
                cursor="pointer"
              >
                <Text
                  variant={TextVariants.LARGE_TERNARY_FONT_BOLD}
                  color={Colors.BLUE}
                  marginLeft="4px"
                >
                  Browse
                </Text>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default ProfilePhotoUploader;
