import React, { ReactNode, CSSProperties, ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { LinkProps } from "./LinkProps";
import "./Link.css"; // Assuming you'll define your hover styles in this CSS file.

const Link: React.FC<LinkProps> = ({
  children,
  color,
  underline,
  className,
  onClick,
  to,
}) => {
  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (to) {
      navigate(to);
    } else if (onClick) {
      onClick(e);
    }
  };

  const styledChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      // Type assertion to ensure 'child' has 'style' prop
      return React.cloneElement(
        child as ReactElement<{ style?: CSSProperties }>,
        {
          style: {
            ...(child.props.style || {}),
            textDecoration: underline ? "underline" : "none",
          },
        }
      );
    }
    return child;
  });

  return (
    <a
      style={{ color, textDecoration: underline ? "underline" : "" }}
      className={`link ${className ?? ""}`}
      onClick={handleClick}
    >
      {styledChildren}
    </a>
  );
};

export default Link;
