// services/razorpay/razorpay-service.ts

import { Appsettings, functions, httpsCallable } from "config";
import { LoggedInUser } from "models";

// Load Razorpay script dynamically
const loadRazorpayScript = () => {
  return new Promise<void>((resolve, reject) => {
    if ((window as any).Razorpay) {
      resolve(); // Razorpay script is already loaded
      return;
    }

    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onload = () => resolve();
    script.onerror = () => reject(new Error("Failed to load Razorpay script."));
    document.body.appendChild(script);
  });
};

// Create a subscription using Firebase function
const createSubscription = async (
  planId: string,
  userId: string,
  customerId: string,
  handleError: () => void
) => {
  const createSubscriptionFn = httpsCallable(functions, "createSubscription");

  try {
    const result = await createSubscriptionFn({
      userId,
      planId,
      customerId,
      // planId: "plan_OgUlvjRzUvvlsz" test plan,
    });
    return result?.data as any; // Adjust based on the actual return type
  } catch (error) {
    console.log(error);
    handleError();
  }
};

// Initiate payment with Razorpay
export const initiatePayment = async (
  planId: string,
  loggedInUser: LoggedInUser,
  handleError: () => void
) => {
  try {
    if (loggedInUser?.email) {
      await loadRazorpayScript();

      const subscription = await createSubscription(
        planId,
        loggedInUser?.userId,
        loggedInUser?.customerId,
        handleError
      );

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID || "",
        // key: "rzp_test_o77coDTcEdpB7Z" test mode key,
        name: Appsettings.appName,
        description: Appsettings.appName + " Premium",
        subscription_id: subscription.id, // Use subscription_id if available
        prefill: {
          name: loggedInUser?.name,
          email: loggedInUser?.email,
        },
        modal: {
          ondismiss: handleError,
        },
      };

      const Razorpay = (window as any).Razorpay;
      const payment = new Razorpay(options);
      payment.open();
      payment.on("payment.failed", function () {
        handleError();
      });
    }
  } catch (_) {
    handleError();
  }
};
