import React from "react";
import { Box } from "../box";
import "./Switch.css";

interface SwitchProps {
  isChecked: boolean;
  onChange: (checked: boolean) => void;
  label?: string; // Optional label for the switch
  className?: string; // Optional className for styling
}

const Switch: React.FC<SwitchProps> = ({
  isChecked,
  onChange,
  label,
  className,
}) => {
  const handleToggle = () => {
    onChange(!isChecked);
  };

  return (
    <Box className={`switch-container ${className}`} onClick={handleToggle}>
      <Box
        className={`switch ${
          isChecked ? "switch-checked" : "switch-unchecked"
        }`}
      >
        <Box className="switch-knob" />
      </Box>
      {label && <Box className="switch-label">{label}</Box>}
    </Box>
  );
};

export default Switch;
