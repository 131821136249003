import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./CreateProfile.css";
import {
  AppRoutes,
  Box,
  Loader,
  LOCAL_STORAGE_KEYS,
  ProfileCreationTracker,
  Text,
  TextVariants,
} from "reusable";
import { useUser, useLocalStorage, useCreator } from "backend";
import CreatorQuestions from "./CreatorQuestions";
import { CreatorProfile } from "models";

const CreateProfile = (): JSX.Element => {
  //imports
  const goTo = useNavigate();
  const { stepInProgress, previewCreatorProfile } = useLocalStorage();
  const {
    creatorAnswers,
    setCreatorAnswers,
    getProfile,
    creatorProfile,
    getSummary,
  } = useCreator();

  //states
  const [step, setStep] = useState<number>(stepInProgress);
  const { loggedInUser, publishProfile, isProfileCreationInProgress } =
    useUser();

  // functions
  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.STEP_IN_PROGRESS,
        String(step - 1)
      );
    } else {
      goTo(-1);
    }
  };

  const handleNextStep = (difference: number) => {
    if (step + difference < 4) {
      setStep(step + difference);
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.STEP_IN_PROGRESS,
        String(step + difference)
      );
    } else {
      const publishCallback = (userId: string) => {
        goTo(AppRoutes.PROFILE.replace(":userId", userId));
      };
      publishProfile(
        {
          ...creatorAnswers,
          userId: loggedInUser?.userId || "",
        },
        publishCallback,
        Boolean(loggedInUser?.isCreatorQuestionsAnswered)
      );
    }
  };

  const handleOnClickStep = (updatedStep: number) => {
    if (stepInProgress > updatedStep) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.STEP_IN_PROGRESS,
        String(updatedStep)
      );
      setStep(updatedStep);
    }
  };

  const setAnswers = (answers: CreatorProfile) => {
    setCreatorAnswers(answers);
  };

  // effects
  useEffect(() => {
    if (Object.keys(previewCreatorProfile)?.length && !creatorProfile?.userId) {
      setAnswers({
        ...previewCreatorProfile,
        profilePicture: creatorAnswers?.profilePicture,
        creatorPhotos: creatorAnswers?.creatorPhotos,
        removedUrls: creatorAnswers?.removedUrls,
        newCreatorImages: creatorAnswers?.newCreatorImages,
        newProfilePicture: creatorAnswers?.newProfilePicture,
      });
    }
  }, []);

  useEffect(() => {
    if (
      loggedInUser?.userId?.length &&
      loggedInUser?.isCreatorQuestionsAnswered
    ) {
      getProfile(loggedInUser?.userId);
      getSummary(loggedInUser?.userId);
    }
  }, [loggedInUser?.userId, loggedInUser]);

  useEffect(() => {
    if (
      creatorProfile?.userId === loggedInUser?.userId &&
      !creatorAnswers?.firstName
    ) {
      setAnswers(creatorProfile as CreatorProfile);
    }
  }, [creatorProfile]);

  return (
    <>
      {!isProfileCreationInProgress ? (
        <Box className="create-profile-container">
          <Box className="create-profile-tracker">
            <Box
              className="header-back-button hide-for-small-only"
              onClick={handleBack}
              position="absolute"
              left="113px"
            ></Box>
            <ProfileCreationTracker
              step={step}
              handleOnClickStep={handleOnClickStep}
            />
          </Box>
          <Box className="app-container creator-questions-container">
            <CreatorQuestions
              step={step}
              setStep={(difference) => handleNextStep(difference)}
              creatorAnswers={creatorAnswers}
              setCreatorAnswers={setAnswers}
            />
          </Box>
        </Box>
      ) : (
        <>
          <Box className="loader-container modal-overlay">
            <Box textAlign="center">
              <Text
                variant={TextVariants.MEDIUM_15_TERNARY_FONT_BOLD}
                lineHeight="20px"
              >
                Please wait while we{" "}
                {loggedInUser?.isCreatorQuestionsAnswered
                  ? "Update "
                  : "Create "}{" "}
                your profile !
              </Text>
              <Box marginTop="20px">
                <Loader />
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default CreateProfile;
