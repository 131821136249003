import { firestoreTimestamp } from "config";
import { CreatorProfile, ProfileSummary } from "models";

// Example of CreatorProfile dummy data

export const dummyRatings = {
  service: 0,
  overall: 0,
  design: 0,
  delivery: 0,
  totalRatings: 0,
};

export const dummyCreatorProfile: CreatorProfile = {
  about: "Passionate about transforming",
  completedProjects: "50+",
  experienceYears: "5",
  languages: "English, Spanish",
  creatorPhotos: ["/arch11.jpg", "/arch11.jpg", "/arch11.jpg", "/arch11.jpg"],
  projectCost: "$5000",
  projectType: "Turnkey",
  name: "John Doe",
  userId: "user-123",
  firstName: "John Doe",
  lastName: "John Doe",
  websiteUrl: "www.myinsta.com",
  instagramLink: "www.myinsta.com",
  occupation: "Architect",
  position: "Business Owner",
  contactNumber: "",
  location: {
    lat: "",
    lng: "",
    place: "",
  },
  creationTime: firestoreTimestamp,
  ratings: dummyRatings,
  universityName: "",
  workExperience: "",
  companyName: "",
  graduationYear: "",
  softwareSkills: "",
  lookingForJob: false,
  isHiring: false,
  feedbackRequests: [],
  theme: {
    background: "",
    color: "",
  },
};

export const dummyUserId = "not-logged-in";

export const dummySummary: ProfileSummary = {
  location: {
    place: "Pune",
    lat: 0,
    lon: 0,
  },
  userId: dummyUserId,
  profilePicture: "",
  occupation: "Architect",
  name: "Name",
  ratings: dummyRatings,
  position: "Business Owner",
  isSubscribed: false,
};

export const dummyImageUrl = "/arch11.jpg";

export const dummyFeedbackQuestions = [
  {
    parentId: "",
    questionId: "overallSatisfactionWithDesignService",
    question:
      "How would you rate your overall satisfaction with the design service",
    isRequired: true,
    order: 1,
    weightage: 1,
  },
];

export const dummyPreviousQuery =
  "default-previous-query-fnffffnddmnfknuldlldundefuned";
