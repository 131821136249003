import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  AppRoutes,
  Box,
  Button,
  Colors,
  Input,
  ProfileSummary,
  Search,
  Text,
  TextVariants,
  dummyPreviousQuery,
  dummySummary,
  dummyUserId,
} from "reusable";
import { useCreator, useUserLocation } from "backend";
import "./ExploreDesigners.css";
import { ProfileSummary as ProfileSummaryType } from "models";

const ExploreDesigners = (): JSX.Element => {
  //imports
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get("search")?.toLowerCase() || "";

  const { userLocation, getUserLocation } = useUserLocation();
  const {
    searchProfileSummaries,
    profileSummaries,
    isSummaryLoading,
    showLoadMoreButton,
    offset,
    previousQuery,
  } = useCreator();
  const { lat, lon } = userLocation;

  //states
  const [selectedButton, setSelectedButton] = useState<number>(0);
  const [exploreButtons] = useState<Array<Record<string, string | number>>>([
    {
      title: "All",
      displayTitle: "All Profiles",
      id: 1,
      query: "all",
    },
    {
      title: "Interior Designers",
      displayTitle: "Interior Designers",
      id: 2,
      query: "interior designer",
    },
    {
      title: "Architects",
      displayTitle: "Architects",
      id: 3,
      query: "architect",
    },
    {
      title: "Nearby",
      displayTitle: "Nearby Locations",
      id: 4,
      query: "nearby",
    },
  ]);

  const summaries =
    isSummaryLoading || previousQuery === dummyPreviousQuery
      ? [
          ...(profileSummaries?.length ? profileSummaries : []),
          dummySummary,
          dummySummary,
          dummySummary,
        ]
      : profileSummaries;
  //functions
  const updateParam = (input: string) => {
    searchParams.set("search", input);
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const requestNearby = () => updateParam("nearby");

  const buttonClickHandler = (
    buttonDetails: Record<string, string | number>
  ) => {
    if (buttonDetails.id === 4) {
      if (lat && lon) {
        setSelectedButton(buttonDetails.id as number);
        updateParam(buttonDetails.query as string);
      } else {
        const showAlertOnError = true;
        getUserLocation(showAlertOnError, requestNearby);
      }
    } else {
      setSelectedButton(buttonDetails.id as number);
      updateParam(buttonDetails.query as string);
    }
  };

  const tileClickHandler = (summary: ProfileSummaryType) => {
    navigate(AppRoutes.PROFILE.replace(":userId", summary?.userId));
  };

  const handleSearch = (input: string) => {
    updateParam(input);
  };

  const handleLoadMore = () => {
    searchProfileSummaries(query, offset + 1);
  };

  const requestAll = () => updateParam("all");

  useEffect(() => {
    if (!query?.length) {
      requestAll();
    }
  }, []);

  // effect
  useEffect(() => {
    const searchQuery = query?.toLowerCase();
    const index =
      exploreButtons.find(
        (button) => String(button.query).toLowerCase() === searchQuery
      )?.id || 0;
    setSelectedButton(index as number);
    searchProfileSummaries(searchQuery || "", undefined, lat, lon);
  }, [query]);

  return (
    <Box className="explore-container">
      <Box className="explore-tools hide-for-small-only">
        <Box className="explore-button-section">
          {exploreButtons.map((button, id) => (
            <Button
              key={id}
              className="explore-button"
              backgroundColor={
                button.id === selectedButton
                  ? Colors.BLACK_DARK
                  : Colors.OFF_WHITE
              }
              onClick={() => buttonClickHandler(button)}
            >
              <Text
                color={
                  button.id === selectedButton ? Colors.WHITE : Colors.GRAY_2
                }
                variant={TextVariants.MEDIUM_PRIMARY_FONT}
              >
                {button.title as string}
              </Text>
            </Button>
          ))}
        </Box>
        <Box>
          <Input
            preIcon={
              <Box margin="4px 20px 0 20px" opacity={0.6}>
                <Search />
              </Box>
            }
            placeholder={
              <Text
                variant={TextVariants.MEDIUM_15_TERNARY_FONT_BOLD}
                color={Colors.BLACK_MAIN}
                opacity="0.6"
                maxWidth="100%"
                textWrap="nowrap"
                overflow="hidden"
                marginTop="5px"
              >
                Search by Name, City or Company Name
              </Text>
            }
            placeholderLeft="66px"
            className="explore-search"
            onChange={(e) => handleSearch(e.target.value)}
            value={selectedButton > 0 ? "" : query}
          />
        </Box>
      </Box>
      <Box className="show-for-small-only" margin="23px 0 21px 0px">
        <Box marginBottom="12px">
          <Input
            preIcon={
              <Box margin="4px 20px 0 20px" opacity={0.6}>
                <Search />
              </Box>
            }
            placeholder={
              <Text
                variant={TextVariants.LARGE_TERNARY_FONT}
                color={Colors.BLACK_MAIN}
                opacity="0.6"
                maxWidth="90%"
                ellipsis
              >
                Search by Name, City or Company Name
              </Text>
            }
            placeholderLeft="66px"
            className="explore-search"
            onChange={(e) => handleSearch(e.target.value)}
            value={selectedButton > 0 ? "" : query}
            width="399px"
            maxWidth="96vw"
          />
        </Box>
        <Box paddingBottom="10px" className="explore-tools-mobile-buttons">
          {exploreButtons.map((button, id) => (
            <Button
              key={id}
              className="explore-button"
              backgroundColor={
                button.id === selectedButton
                  ? Colors.BLACK_DARK
                  : Colors.OFF_WHITE
              }
              onClick={() => buttonClickHandler(button)}
            >
              <Text
                color={
                  button.id === selectedButton ? Colors.WHITE : Colors.GRAY_2
                }
                variant={TextVariants.MEDIUM_PRIMARY_FONT}
              >
                {button.title as string}
              </Text>
            </Button>
          ))}
        </Box>
      </Box>
      <Box className="search-results-section">
        <Text
          variant={TextVariants.LARGE_SECONDARY_FONT}
          color={Colors.GRAY_3}
          className="search-result-text"
        >
          Search Results for
          <Text
            variant={TextVariants.LARGE_TERNARY_FONT_BOLD}
            color={Colors.BLACK_DARK}
            marginLeft="6px"
          >
            {selectedButton > 0
              ? exploreButtons[selectedButton - 1]?.displayTitle
              : query}
          </Text>
        </Text>
        <Box
          width="100%"
          minHeight="300px"
          marginTop="20px"
          cursor="pointer"
          borderTop="1px #E5E7EB solid"
          paddingTop="25px"
          className="explore-wrapper"
        >
          <Box className="summary-container">
            {summaries?.map((summary, index) => (
              <Box
                onClick={() => tileClickHandler(summary)}
                display="inline-block"
                margin="0 16px 16px 0px"
                borderRadius="12px"
                className="profile-summary-container"
                key={index}
                isLoading={summary?.userId === dummyUserId}
                showSkeleton
              >
                <ProfileSummary summary={summary} />
              </Box>
            ))}
            {summaries !== null && summaries?.length === 0 && (
              <Box textAlign="center">
                <Text
                  variant={TextVariants.LARGE_SECONDARY_FONT}
                  color={Colors.GRAY_3}
                >
                  No Search Results
                </Text>
              </Box>
            )}
          </Box>
        </Box>
        {showLoadMoreButton && (
          <Box
            marginTop="21px"
            display="flex"
            width="100%"
            justifyContent="center"
            onClick={handleLoadMore}
            marginBottom="23px"
          >
            <Button className="explore-more-button" disabled={isSummaryLoading}>
              Explore More Designers
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ExploreDesigners;
