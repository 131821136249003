export enum Colors {
  BLACK = "#202020",
  BLACK_DARK = "#1F2937",
  BLACK_MAIN = "black",
  GRAY = "#212C0A",
  GRAY_1 = "rgba(31, 41, 55, 0.75)",
  GRAY_2 = "#6B7280",
  GRAY_3 = "#575F69",
  GRAY_4 = "rgba(0, 0, 0, 0.50)",
  GRAY_5 = "#9CA3AF",
  GRAY_FAINT = "#E5E7EB",
  ORANGE = "#FF5043",
  ORANGE_FAINT = "rgba(255, 114, 98, 0.11)",
  WHITE_CLOSE = "rgba(255, 255, 255, 0.50)",
  WHITE = "white",
  LINK_BLUE = "#2563EB",
  BLUE = "#4285F4",
  OFF_WHITE = "#F3F4F6",
  OFF_WHITE_2 = "#F9FAFC",
  PURPLE = "#6B66DC",
  TRANSPARENT = "transparent",
}
