import React, { useEffect, useState } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import Autocomplete from "./Autocomplete";
import { RegularInput } from "../regular-input";
import { useAlerts } from "backend";
import { Location } from "models";

const LocationAutocomplete = (props: {
  selectedLocation: Location;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}): JSX.Element => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();
  const { addAlert } = useAlerts();
  const { selectedLocation, onChange } = props;

  const [latLng, setLatLng] = useState<{ lat: number; lng: number } | null>(
    null
  );

  const handleSelect = async (value: string) => {
    try {
      const results = await getGeocode({ address: value });
      const { lat, lng } = await getLatLng(results[0]);
      setLatLng({ lat, lng });
      onChange({
        target: { value: { lat, lng, place: value } },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
      setValue(value);
      clearSuggestions();
    } catch (e) {
      console.log("Error found in location autocomplete component", e);
      addAlert({ message: " Something went wrong", type: "error" });
    }
  };

  useEffect(() => {
    if (selectedLocation?.place) {
      setValue(selectedLocation?.place);
    }
  }, [selectedLocation]);

  return (
    <>
      {ready ? (
        <Autocomplete
          suggestions={
            status === "OK" ? data.map(({ description }) => description) : []
          }
          onSelect={handleSelect}
          placeholder="Type a city name"
          onChange={setValue}
          userInput={value}
        />
      ) : (
        <RegularInput
          value={selectedLocation?.place ?? ""}
          onChange={(e) =>
            onChange({
              target: { value: { ...latLng, place: e.target.value } },
            } as unknown as React.ChangeEvent<HTMLInputElement>)
          }
          placeholder="Please Enter Location"
        />
      )}
    </>
  );
};

export default LocationAutocomplete;
