import React from "react";
import { Box } from "reusable";
import "./Loader.css";

interface ArrowLoaderProps {
  height?: string;
  width?: string;
}

const ArrowLoader: React.FC<ArrowLoaderProps> = ({
  height = "50px",
  width = "50px",
}) => {
  return (
    <Box display="inline-block">
      <Box className="arrow-loader" height={height} width={width}>
        <Box className="arrow arrow1" />
        <Box className="arrow arrow2" />
      </Box>
    </Box>
  );
};

export default ArrowLoader;
