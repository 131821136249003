import { useEffect, useRef, useState } from "react";
import { DropdownCross } from "reusable";
import { Box } from "../box";
import { Button } from "../button";
import "./Dropdown.css";

const Dropdown = (props: {
  title: string | React.ReactNode;
  options: Array<Record<string, string | void>>;
  disabled?: boolean;
  crossWidth?: string;
}): JSX.Element => {
  const { options, disabled = false, title, crossWidth } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const shareMenuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      shareMenuRef.current &&
      !shareMenuRef.current.contains(event.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setIsMenuOpen(false);
    }
  };

  const adjustDropdownPosition = () => {
    if (isMenuOpen && shareMenuRef.current && buttonRef.current) {
      const dropdownRect = shareMenuRef.current.getBoundingClientRect();
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // Adjust horizontal positioning if dropdown overflows viewport
      if (dropdownRect.right > viewportWidth) {
        shareMenuRef.current.classList.add("adjust-left");
      } else {
        shareMenuRef.current.classList.remove("adjust-left");
      }

      // Adjust vertical positioning if dropdown overflows viewport
      if (dropdownRect.bottom > viewportHeight) {
        shareMenuRef.current.classList.add("open-up");
      } else {
        shareMenuRef.current.classList.remove("open-up");
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    adjustDropdownPosition();
  }, [isMenuOpen]);

  return (
    <Box className="dropdown-component">
      <Box ref={buttonRef}>
        {isMenuOpen ? (
          <Box onClick={handleToggleMenu} width={crossWidth}>
            <DropdownCross />
          </Box>
        ) : typeof title === "string" ? (
          <Button
            onClick={handleToggleMenu}
            disabled={disabled}
            className="dropdown-button"
          >
            {title}
          </Button>
        ) : (
          <Box onClick={handleToggleMenu}>{title}</Box>
        )}
      </Box>
      {isMenuOpen && (
        <Box
          ref={shareMenuRef}
          className={`dropdown-component-menu ${isMenuOpen ? "show" : ""}`}
        >
          <ul className="dropdown-component-list">
            {options?.map((option, index) => (
              <li
                key={index}
                className="dropdown-component-item"
                onClick={() => {
                  (option?.onClick as any)();
                  handleToggleMenu();
                }}
              >
                <span>{option.title as string}</span>
              </li>
            ))}
          </ul>
        </Box>
      )}
    </Box>
  );
};

export default Dropdown;
