import { TextProps } from "./TextProps";
import "./Text.css";

const Text = (props: TextProps): JSX.Element => {
  //imports
  const {
    children,
    variant,
    className,
    ellipsis = false,
    defaultText = "-",
    ...styles
  } = props;

  const getChildren = (children: React.ReactNode) => {
    if (!children) {
      return defaultText;
    }
    return children;
  };

  const additionalStyles = ellipsis
    ? {
        display: "block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineHeight: "23px",
      }
    : {};

  return (
    <span
      className={`text ${variant} ${className ?? ""}`}
      style={{ ...styles, ...additionalStyles }}
    >
      {getChildren(children)}
    </span>
  );
};

export default Text;
