import { useState } from "react";
import Autocomplete from "./Autocomplete";
import { softwares } from "reusable";

const SoftwareAutocomplete = (props: {
  input: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}): JSX.Element => {
  const { onChange, input } = props;
  const [suggestions, setSuggestions] = useState<Array<string>>([]);

  const onInputChange = (value: string) => {
    if (value !== undefined) {
      const selectedSoftwares = value?.split(", ");
      const searchTerm = (
        (selectedSoftwares as any)?.pop() || value
      )?.toLowerCase();
      onChange({
        target: { value: value },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
      setSuggestions(
        value
          ? softwares.filter(
              (s) =>
                s?.toLowerCase().includes(searchTerm) &&
                !selectedSoftwares?.includes(s)
            )
          : []
      );
    }
  };

  const onSoftwareSelect = (value: string) => {
    if (value) {
      const selectedValues = input?.split(", ");
      selectedValues?.pop();
      const filteredInput = selectedValues?.join(", ");
      setSuggestions([]);
      onChange({
        target: {
          value:
            selectedValues?.length > 0
              ? filteredInput + ", " + value + ", "
              : value + ", ",
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <Autocomplete
      suggestions={suggestions}
      onChange={onInputChange}
      onSelect={onSoftwareSelect}
      userInput={input}
      placeholder="Type Software"
      onEnter={() => console.log("")}
    />
  );
};

export default SoftwareAutocomplete;
