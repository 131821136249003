import React from "react";
import "./Modal.css"; // Import the CSS file for styling
import { Box, ModalCross } from "reusable";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: React.ReactNode;
  children?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <Box className="modal-overlay" onClick={onClose}>
      <Box className="modal-component">
        <Box className="modal-title">{title}</Box>
        <Box className="modal-content">{children}</Box>
        <Box className="modal-cross" onClick={onClose}>
          <ModalCross />
        </Box>
      </Box>
    </Box>
  );
};

export default Modal;
