import { ReactNode } from "react";
import { Box, Colors, TextVariants, Text, RegularInput } from "reusable";

const SignUpInput = (input: {
  title?: string;
  placeholder?: ReactNode;
  preIcon?: ReactNode;
  postIcon?: ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}) => {
  const { title, placeholder, preIcon, postIcon, onChange, value, ...rest } =
    input;
  return (
    <Box marginTop="9px">
      {
        <Text
          color={Colors.BLACK_DARK}
          marginBottom="10px"
          display="block"
          variant={TextVariants.MEDIUM_TERNARY_FONT_EXTRA_BOLD}
          {...rest}
        >
          {title}
        </Text>
      }
      <RegularInput {...input} />
    </Box>
  );
};

export default SignUpInput;
