import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Alert,
  Box,
  GLOBAL_STYLES,
  LOCAL_STORAGE_KEYS,
  ScrollToTop as Navigations,
  GoogleScript,
  Colors,
  PageTransition as PageTransitionWrapper,
  AppRoutes,
  AuthRoute,
} from "reusable";
import "./App.css";
import { Footer, Header } from "components";
import {
  AboutUs,
  CreateProfile,
  ExploreDesigners,
  Feedback,
  GetRating,
  Landing,
  Pricing,
  PrivacyPolicy,
  Profile,
  SignUp,
  TermsOfUse,
  ContactUs,
} from "screens";
import {
  useUser,
  useLocalStorage,
  useInternetConnection,
  useHelmet,
} from "backend";
import { Appsettings } from "config";

const App = () => {
  const { subscribeUser } = useUser();
  const { isMobileScreen, isFreshSession } = useLocalStorage();
  const { HelmetComponent } = useHelmet({
    title: "Kydoscope",
    description:
      "Home for interior designers and architects. Find nearby designers and architects.",
    themeColor: Colors.ORANGE,
  });

  useInternetConnection();

  useEffect(() => {
    subscribeUser();
    const screenWidth = window.innerWidth;
    if (screenWidth < GLOBAL_STYLES.MOBILE_SCREEN_WIDTH) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.IS_MOBILE_SCREEN, "true");
    } else {
      localStorage.setItem(LOCAL_STORAGE_KEYS.IS_MOBILE_SCREEN, "false");
    }

    setTimeout(() => {
      if (isFreshSession) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.IS_FRESH_SESSION, "false");
      }
    }, 3000);
  }, []);

  return (
    <Router>
      <PageTransitionWrapper>
        <HelmetComponent />
        <GoogleScript
          apiKey={Appsettings.googleapikey}
          onLoad={() => "script loaded"}
        />
        <Box className="kydoscope">
          <Pricing />
          <Header />
          <Navigations />
          {!Appsettings.isLive && (
            <Box className="maintenance-banner">
              <Alert
                type="info"
                message="Site is under Maintenance, please visit later."
                autoHide={false}
                displayCross={false}
              />
            </Box>
          )}
          <Box minHeight="calc(100vh - 240px)">
            <Routes>
              <Route path={AppRoutes.LANDING} element={<Landing />} />
              <Route path={AppRoutes.LOGIN} element={<SignUp isLoginMode />} />
              <Route path={AppRoutes.SIGN_UP} element={<SignUp />} />
              <Route path={AppRoutes.PROFILE} element={<Profile />} />
              <Route
                path={AppRoutes.PROFILE_PREVIEW}
                element={<Profile isPreview />}
              />
              <Route
                path={AppRoutes.FILL_PROFILE_DETAILS}
                element={<AuthRoute component={CreateProfile} />}
              />
              <Route
                path={AppRoutes.EXPLORE_DESIGNERS}
                element={<ExploreDesigners />}
              />
              <Route
                path={AppRoutes.FEEDBACK}
                element={<AuthRoute component={Feedback} />}
              />
              <Route
                path={AppRoutes.TERMS_AND_CONDITIONS}
                element={<TermsOfUse />}
              />
              <Route
                path={AppRoutes.PRIVACY_POLICY}
                element={<PrivacyPolicy />}
              />
              <Route
                path={AppRoutes.GET_RATING}
                element={<AuthRoute component={GetRating} />}
              />
              <Route
                path={AppRoutes.CONTACT_US}
                element={<AuthRoute component={ContactUs} />}
              />
              <Route path={AppRoutes.ABOUT_US} element={<AboutUs />} />
            </Routes>
          </Box>
          <Footer />
        </Box>
        <Box className="no-support">
          {isMobileScreen
            ? "Please rotate your phone"
            : "Currently we are only supporting Phone and Laptop size for Kydoscope !"}
        </Box>
      </PageTransitionWrapper>
    </Router>
  );
};

export default App;
