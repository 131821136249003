import { useRecoilState } from "recoil";
import { LocationService } from "../../services/location/location-service";
import { useAlerts } from "../alert/use-alert";
import { userLocationAtom } from "../../data/location-atoms";
import { useLocalStorage } from "../local-storage/use-local-storage";
import { Location } from "models";

export function useUserLocation() {
  const [userLocation, setUserLocation] = useRecoilState(userLocationAtom);
  const { addAlert } = useAlerts();
  const { isFreshSession } = useLocalStorage();

  const getUserLocation = async (
    showErrorAlert?: boolean,
    callback?: () => void
  ) => {
    const handleSuccess = (location: Location) => {
      setUserLocation(location);
      if (callback) callback();
    };

    const handleError = () => {
      if (isFreshSession || showErrorAlert) {
        addAlert({
          modalTitle: "Oops! It looks like location access is turned off.",
          autoHide: false,
          message: "",
          type: "error",
          structure: "modal",
          modalDescription:
            "To help you find the best designers around you, please enable location services.",
        });
      }
    };

    LocationService.getUserLocation(handleSuccess, handleError);
  };

  return { getUserLocation, userLocation };
}
