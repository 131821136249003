import { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  AppRoutes,
  Box,
  Colors,
  Instagram,
  Link,
  Text,
  TextVariants,
  Twitter,
  Youtube,
} from "reusable";
import "./Footer.css";
import { Appsettings } from "config";

const Footer = (): JSX.Element => {
  const path = useLocation().pathname;
  const display = !(
    path === AppRoutes.SIGN_UP ||
    path.includes("fill-details") ||
    path === AppRoutes.LOGIN ||
    path.includes("feedback")
  );

  const [links] = useState<Array<Record<string, string>>>([
    {
      title: "Find Designer",
      to: AppRoutes.EXPLORE_DESIGNERS,
    },
    {
      title: "Create Profile",
      to: AppRoutes.FILL_PROFILE_DETAILS,
    },
    // {
    //   title: "FAQ",
    //   to: "",
    // },
    // {
    //   title: "Blogs",
    //   to: "",
    // },
    {
      title: "About us",
      to: AppRoutes.ABOUT_US,
    },
    {
      title: "Contact us",
      to: AppRoutes.CONTACT_US,
    },
    {
      title: "Terms of Use",
      to: AppRoutes.TERMS_AND_CONDITIONS,
    },
    {
      title: "Privacy Policy",
      to: AppRoutes.PRIVACY_POLICY,
    },
  ]);

  const SocialMediaSection = (props: { distance: string }) => {
    const { distance } = props;
    const socialMediaIcons = [
      <Box
        onClick={() =>
          window.open(Appsettings.socialMediaUrls["instagram"], "_blank")
        }
      >
        <Instagram />
      </Box>,
      <Box
        onClick={() =>
          window.open(Appsettings.socialMediaUrls["twitter"], "_blank")
        }
      >
        <Twitter />
      </Box>,
      <Box
        onClick={() =>
          window.open(Appsettings.socialMediaUrls["youtube"], "_blank")
        }
      >
        <Youtube />
      </Box>,
    ];
    return (
      <Box
        className="social-media-section"
        display="inline-flex"
        alignItems="center"
      >
        {socialMediaIcons.map((icon) => (
          <Box cursor="pointer" marginLeft={distance}>
            {icon}
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box className="footer-component">
      {display && (
        <Box>
          <Box padding="0 75px" className="hide-for-small-only">
            <Box
              id="footer"
              display="flex"
              justifyContent="space-between"
              padding="30px 0"
            >
              <Box>
                {links
                  .filter((_, i) => i < 3)
                  .map((link) => (
                    <Link
                      key={link.title}
                      to={link.to}
                      className={link.to ? "" : "disabled"}
                    >
                      <Text
                        color={Colors.GRAY_1}
                        variant={TextVariants.MEDIUM_PRIMARY_FONT}
                        marginRight="18px"
                      >
                        {link.title}
                      </Text>
                    </Link>
                  ))}
              </Box>
              <Box>
                {links
                  .filter((_, i) => i > 2)
                  .map((link, index) => (
                    <Link
                      key={link.title}
                      to={link.to}
                      className={link.to ? "" : "disabled"}
                    >
                      <Text
                        color={Colors.GRAY_1}
                        variant={TextVariants.MEDIUM_PRIMARY_FONT}
                        marginRight={index < 3 ? "18px" : ""}
                      >
                        {link.title}
                      </Text>
                    </Link>
                  ))}
              </Box>
            </Box>
            <Box
              padding="30px 0"
              display="flex"
              justifyContent="space-between"
              className="footer-copyright"
            >
              <Box>
                <Text
                  color={Colors.GRAY_1}
                  variant={TextVariants.SMALL_PRIMARY_FONT}
                >
                  © Kydoscope Ventures LLP 2024
                </Text>
              </Box>
              <Box>
                <SocialMediaSection distance="15px" />
              </Box>
            </Box>
          </Box>
          <Box marginTop="30px" className="show-for-small-only">
            <Box
              display="flex"
              justifyContent="space-around"
              marginBottom="15px"
            >
              {links
                .filter((_, i) => i < 3)
                .map((link, index) => (
                  <Box>
                    <Link to={link.to} className={link.to ? "" : "disabled"}>
                      <Text
                        color={Colors.GRAY_1}
                        variant={TextVariants.MEDIUM_PRIMARY_FONT}
                      >
                        {link.title}
                      </Text>
                    </Link>
                  </Box>
                ))}
            </Box>
            <Box className="footer-gray-area">
              <Box
                display="flex"
                justifyContent="space-around"
                marginBottom="20px"
              >
                {links
                  .filter((_, i) => i > 2 && i < 8)
                  .map((link, index) => (
                    <Box key={index + 3}>
                      <Link to={link.to} className={link.to ? "" : "disabled"}>
                        <Text
                          color={Colors.GRAY_1}
                          variant={TextVariants.MEDIUM_PRIMARY_FONT}
                        >
                          {link.title}
                        </Text>
                      </Link>
                    </Box>
                  ))}
              </Box>
              <Box textAlign="center">
                <Text
                  color={Colors.GRAY_1}
                  variant={TextVariants.SMALL_PRIMARY_FONT}
                >
                  Copyright {Appsettings.currentYear} - {Appsettings.appName}
                </Text>
              </Box>
            </Box>
            <Box padding="13px 41px 26px 41px" textAlign="right">
              <SocialMediaSection distance="37px" />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Footer;
