import { Appsettings } from "config";
import { Box, Colors, Text, TextVariants } from "reusable";

const TermsOfUse = (): JSX.Element => {
  return (
    <Box className="document-component">
      <Box className="document-component-wrapper">
        <Box width="100%" textAlign="center">
          <Text
            variant={TextVariants.HEADING_QUARTERNARY_FONT}
            textAlign="center"
            maxWidth="400px"
          >
            <Text
              variant={TextVariants.HEADING_QUARTERNARY_FONT}
              color={Colors.ORANGE}
              marginRight="8px"
            >
              {Appsettings.appName}
            </Text>
            Terms & Conditions
          </Text>
        </Box>
        <div className="document-content">
          By accessing or using Kydoscope (the &quot;Site&quot;), you
          (&quot;User&quot;) agree to comply with and be bound by these Terms of
          Use (&quot;Terms&quot;). If you do not agree to these Terms, you must
          not use the Site.
          <br />
          <br />
          Users must be at least 18 years of age. By registering, you represent
          and warrant that all information you provide is accurate, current, and
          complete. You are responsible for maintaining the confidentiality of
          your password and for any activities that occur under your account.
          You must notify us immediately of any unauthorized use of your
          account.
          <br />
          <br />
          Users shall use the Site in a lawful and respectful manner. Prohibited
          activities include posting content that is offensive, defamatory,
          obscene, or otherwise objectionable; harassing, threatening, or
          abusing others; impersonating any person or entity; and engaging in
          activities that disrupt or interfere with the Site.
          <br />
          <br />
          Users retain ownership of content they post but grant KYDOSCOPE
          VENTURES LLP a non-exclusive, royalty-free, worldwide license to use,
          reproduce, modify, adapt, publish, and display such content in
          connection with the Site. All content provided by the Site is
          protected by intellectual property laws and may not be used without
          our prior written consent.
          <br />
          <br />
          Please review our Privacy Policy for information on how we collect,
          use, and disclose personal information.
          <br />
          <br />
          We reserve the right to terminate or suspend User accounts at our sole
          discretion, without notice, for conduct that we believe violates these
          Terms or is otherwise harmful to the Site or its users.
          <br />
          <br />
          The Site is provided &quot;as is&quot; and &quot;as available&quot;
          without warranties of any kind. KYDOSCOPE VENTURES LLP disclaims all
          warranties, express or implied, including, but not limited to, implied
          warranties of merchantability and fitness for a particular purpose. We
          are not liable for any indirect, incidental, special, consequential,
          or punitive damages arising from your use of the Site.
          <br />
          <br />
          Users agree to indemnify and hold harmless KYDOSCOPE VENTURES LLP, its
          affiliates, officers, directors, employees, and agents from any
          claims, liabilities, damages, losses, and expenses, including
          reasonable attorneys' fees, arising out of or in any way connected
          with their use of the Site or violation of these Terms.
          <br />
          <br />
          These Terms shall be governed by and construed in accordance with the
          laws of India, without regard to its conflict of law principles. Any
          legal action or proceeding arising under these Terms shall be brought
          exclusively in the courts located in Pune.
          <br />
          <br />
          We reserve the right to modify these Terms at any time. Changes will
          be effective immediately upon posting on the Site. Continued use of
          the Site constitutes acceptance of such changes.
          <br />
          <br />
          For questions, please contact us at{" "}
          <a style={{ color: Colors.ORANGE }} href="mailto:info@kydscope.in">
            info@kydscope.in
          </a>
          .<br />
          <br />
          By using the Site, you acknowledge that you have read, understood, and
          agree to be bound by these Terms.
        </div>
      </Box>
    </Box>
  );
};

export default TermsOfUse;
