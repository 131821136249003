// useAlerts.ts
import { useRecoilState } from "recoil";
import { alertsAtom } from "../../data/alert-atoms";
import { Alert } from "models";

export function useAlerts() {
  const [alerts, setAlerts] = useRecoilState(alertsAtom);

  const addAlert = (alert: Alert): void => {
    setAlerts((prevAlerts) => [...prevAlerts, alert]);
  };

  const removeAlert = (index: number): void => {
    setAlerts((prevAlerts) => prevAlerts.filter((_, i) => i !== index));
  };

  return { addAlert, removeAlert, alerts };
}
