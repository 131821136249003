import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCreator, useLocalStorage, useUser } from "backend";
import { Box, ProfileCreationTracker, dummyCreatorProfile } from "reusable";
import "./Profile.css";
import ProfileTile from "./components/ProfileTile";
import PhotoSection from "./components/PhotosSection";
import { CreatorProfile } from "models";

const Profile = (props: { isPreview?: boolean }): JSX.Element => {
  const { isPreview } = props;
  const { userId } = useParams();
  const { getProfile, creatorProfile, isLoading, creatorAnswers } =
    useCreator();
  const { isProfileCreationInProgress } = useUser();
  const [previewProfile, setPreviewProfile] = useState<CreatorProfile>();
  const { previewCreatorProfile } = useLocalStorage();

  useEffect(() => {
    if (userId?.length) {
      if (isPreview) {
        const profilePictureBase64 = creatorAnswers?.profilePicture;
        const creatorPhotosBase64 = creatorAnswers?.creatorPhotos;
        const profileInfo = previewCreatorProfile?.firstName
          ? previewCreatorProfile
          : creatorAnswers;
        setPreviewProfile({
          ...profileInfo,
          profilePicture: profilePictureBase64,
          creatorPhotos: creatorPhotosBase64,
          removedUrls: creatorAnswers?.removedUrls,
          newCreatorImages: creatorAnswers?.newCreatorImages,
          newProfilePicture: creatorAnswers?.newProfilePicture,
        });
      } else {
        getProfile(userId);
      }
    }
  }, [userId, isPreview, creatorAnswers]);

  return (
    <>
      {isProfileCreationInProgress ? (
        <Box className="profile-building" isLoading={!isLoading}>
          Wait! Kydoscope is building your profile.
        </Box>
      ) : (
        <Box>
          {isPreview && (
            <Box
              display="flex"
              padding="19px 0"
              borderBottom="1px #f7f7f7 solid"
              width="100%"
              justifyContent="center"
              className="create-profile-tracker"
              alignItems="center"
              position="fixed"
              left="0"
              background="white"
              height="70px"
            >
              <ProfileCreationTracker
                step={4}
                handleOnClickStep={() => console.log("")}
              />
            </Box>
          )}

          <Box
            className={`profile-container ${
              isPreview ? "preview-profile-container" : ""
            }`}
            isLoading={isLoading && !isPreview}
            showSkeleton
          >
            {<ProfileTile profile={creatorProfile || dummyCreatorProfile} />}
            <Box width="100%" className="hide-for-small-only">
              <PhotoSection
                urls={
                  (isPreview
                    ? previewProfile?.creatorPhotos
                    : creatorProfile?.creatorPhotos) ||
                  dummyCreatorProfile?.creatorPhotos
                }
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Profile;
