import { LOCAL_STORAGE_KEYS } from "reusable";

export function useLocalStorage() {
  // -----------------------Preview Profile Start -----------------------------------------
  const localStorageProfile = JSON.parse(
    (localStorage.getItem(LOCAL_STORAGE_KEYS.CREATOR_PROFILE) as string) || "{}"
  );

  const previewCreatorProfile = {
    ...localStorageProfile,
  };

  const isPreview = Boolean(localStorageProfile?.firstName);

  // -----------------------Preview Profile End -----------------------------------------

  // -----------------------Step In Progress Start -----------------------------------------
  const stepInProgress =
    Number(localStorage.getItem(LOCAL_STORAGE_KEYS.STEP_IN_PROGRESS)) || 1;
  // -----------------------Step In Progress End -----------------------------------------

  const isMobileScreen =
    localStorage.getItem(LOCAL_STORAGE_KEYS.IS_MOBILE_SCREEN) === "true";

  const isFreshSession =
    localStorage.getItem(LOCAL_STORAGE_KEYS.IS_FRESH_SESSION) !== "false";

  const isPremiumAsked =
    localStorage.getItem(LOCAL_STORAGE_KEYS.IS_ASKED_FOR_PREMIUM) === "true";

  const isAskedToCompletePremiumProfile =
    localStorage.getItem(
      LOCAL_STORAGE_KEYS.IS_ASKED_TO_COMPLETE_PREMIUM_PROFILE
    ) === "true";

  return {
    previewCreatorProfile,
    stepInProgress,
    isPreview,
    isMobileScreen,
    isFreshSession,
    isPremiumAsked,
    isAskedToCompletePremiumProfile,
  };
}
