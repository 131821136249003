import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "backend";
import { AppRoutes } from "reusable";

type CallbackFunction = () => void;

const useAuthCallback = (
  callback: CallbackFunction,
  afterLogin?: CallbackFunction,
  notLoggedIn?: CallbackFunction
) => {
  const { loggedInUser } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  const protectedCallback = () => {
    if (!loggedInUser?.email) {
      if (notLoggedIn) {
        notLoggedIn();
      }
      // Save the current location to return to after login
      (window as any).afterLogin = afterLogin;
      navigate(AppRoutes.LOGIN, {
        state: { from: location, afterLogin: afterLogin ? true : false },
      });
    } else {
      // If the user is logged in, execute the callback
      callback();
    }
  };

  return protectedCallback;
};

export default useAuthCallback;
