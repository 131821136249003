import { ChangeEvent } from "react";
import {
  Box,
  Checkbox,
  Colors,
  RegularInput,
  TextVariants,
  Text,
  CreatorQuestionIds,
  Input,
  Chip,
  LocationAutocomplete,
  LanguageAutocomplete,
  SoftwareAutocomplete,
  Switch,
  PremiumChip,
} from "reusable";
import { Question } from "../Question";
import UploadPhoto from "./UploadPhoto";
import { Location } from "models";

const RenderInputField = (props: {
  question: Question;
  value: string | Array<string> | Array<Record<string, string>> | FileList;
  onChange: (
    event: ChangeEvent<HTMLInputElement>,
    detector: Question,
    subQuestion?: Record<string, string>
  ) => void;
}): JSX.Element => {
  //imports
  const { question, value, onChange } = props;

  const themeColours = [
    { background: "#DDC6E7", color: "#9A67AD" },
    { background: "#B6DBF3", color: "#6BAEF9" },
    { background: "#ACE9DD", color: "#6EC7B5" },
    { background: "#FCDCA9", color: "#F0C3A3" },
    { background: "#F8C0BA", color: "#FF7262" },
    { background: "#E3D1B8", color: "#BBAC98" },
    { background: "#F9FAFC", color: "#1F2937" },
    { background: "#B4BCC2", color: "#575F69" },
    { background: "", color: "" },
    { background: "", color: "" },
    { background: "", color: "" },
    { background: "", color: "" },
  ];

  const getPreIcon = () => {
    switch (question?.preIcon) {
      case "ContactNumber":
        return (
          <Box>
            <Text
              variant={TextVariants.MEDIUM_15_TERNARY_FONT_BOLD}
              color={Colors.GRAY_2}
            >
              +91
            </Text>
          </Box>
        );
      default:
        return null;
    }
  };

  switch (question.type) {
    case "switch":
      return (
        <>
          <Switch
            isChecked={value as unknown as boolean}
            onChange={(answer) =>
              onChange({ target: { value: answer } } as any, question)
            }
          />
          <Box display="flex" justifyContent="space-between">
            <Text
              variant={TextVariants.MEDIUM_PRIMARY_FONT}
              color={Colors.GRAY_5}
              marginRight="10px"
            >
              Your profile will have a looking for a job banner.
            </Text>
            <Text
              variant={TextVariants.MEDIUM_PRIMARY_FONT}
              color={Colors.GRAY_5}
            >
              <PremiumChip />
            </Text>
          </Box>
        </>
      );
    case "location-autocomplete":
      return (
        <LocationAutocomplete
          selectedLocation={value as unknown as Location}
          onChange={(e) => onChange(e, question)}
        />
      );
    case "language-chip":
      return (
        <LanguageAutocomplete
          onChange={(e) => onChange(e, question)}
          input={value as string}
        />
      );
    case "software-chip":
      return (
        <SoftwareAutocomplete
          onChange={(e) => onChange(e, question)}
          input={value as string}
        />
      );
    case "textArea":
      return (
        <Input
          inputType="textarea"
          placeholder={
            question?.preIcon ? "" : question?.placeHolder || "Start Typing"
          }
          value={(value as string) || ""}
          onChange={(e) => onChange(e, question)}
        />
      );
    case "text":
      return (
        <>
          {question?.options?.length ? (
            question?.options?.map((option, index) => (
              <RegularInput
                key={index}
                placeholder={
                  (option as unknown as { title: string; placeholder: string })
                    .placeholder || "Start Typing"
                }
                preIcon={getPreIcon()}
                value={
                  value
                    ? (
                        (value as Record<string, string>[])?.find(
                          (v) =>
                            v?.questionID ===
                            (
                              option as {
                                title: string;
                                placeholder: string;
                                questionID: string;
                              }
                            )?.questionID
                        ) as Record<string, string>
                      )[
                        (
                          option as {
                            title: string;
                            placeholder: string;
                            questionID: string;
                          }
                        )?.questionID as string
                      ]
                    : ""
                }
                onChange={(e) =>
                  onChange(e, question, option as Record<string, string>)
                }
              />
            ))
          ) : (
            <RegularInput
              placeholder={
                question?.preIcon ? "" : question?.placeHolder || "Start Typing"
              }
              preIcon={getPreIcon()}
              value={(value as string) || ""}
              onChange={(e) => onChange(e, question)}
              className={`regular-input-${question?.type}`}
              type={question?.inputType}
            />
          )}
        </>
      );
    case "checkbox":
      return (
        <Checkbox
          options={String(question.options)?.split(", ") as Array<string>}
          onChange={(e) => onChange(e, question)}
          checkedValues={(String(value)?.split(", ") as Array<string>) || [""]}
          multiple={question?.multiple}
        />
      );
    case "photo":
      return (
        <UploadPhoto
          type={
            question.questionID === CreatorQuestionIds.PROFILE_PICTURE
              ? "profile-photo"
              : "creator-photos"
          }
          onImageChange={(e) => onChange(e, question)}
          existingImages={value as string[] | string}
        />
      );
    case "theme":
      return (
        <Box marginTop="15px">
          <Box className="theme-box-wrapper" marginBottom="25px">
            {themeColours
              .filter((_, i) => i < 6)
              .map((c: any) => (
                <Box
                  cursor={c?.background?.length ? "pointer" : ""}
                  className={`theme-box ${
                    c?.background === (value as any)?.background
                      ? "selected-theme"
                      : ""
                  }`}
                  backgroundColor={c?.background || ""}
                  onClick={(e: any) => onChange({ ...e, theme: c }, question)}
                ></Box>
              ))}
          </Box>
          <Box className="theme-box-wrapper" marginBottom="25px">
            {themeColours
              .filter((_, i) => i > 5)
              .map((c: any) => (
                <Box
                  cursor={c?.background?.length ? "pointer" : ""}
                  className={`theme-box ${
                    c?.background === (value as any)?.background
                      ? "selected-theme"
                      : ""
                  }`}
                  backgroundColor={c.background}
                  onClick={(e: any) => onChange({ ...e, theme: c }, question)}
                ></Box>
              ))}
          </Box>
        </Box>
      );
    default:
      return <RegularInput />;
  }
};

export default RenderInputField;
