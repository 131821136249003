export enum FIRESTORE_KEYS {
  USER_COLLECTION = "users",
  CREATOR_PROFILES_COLLECTION = "creator-profiles",
  CREATOR_PROFILES_SUMMARY = "creator-profiles-summary",
  SUBMITTED_FEEDBACKS_SUB_COLLECTION = "submitted-feedbacks",
  RECEIVED_FEEDBACKS_SUB_COLLECTION = "received-feedbacks",
  RATINGS = "ratings",
  CREATOR_PHOTOS_FOLDER = "user_photos",
  FEEDBACKS = "feedbacks",
}
