import React, { useEffect, useRef } from "react";
import { Box } from "../box";
import { ProgressTrackerProps } from "./ProgressTrackerProps";
import "./ProgressTracker.css";

const ProgressTracker = (props: ProgressTrackerProps): JSX.Element => {
  const { options, selectedOption, onClick } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current && selectedOption > 0) {
      const selectedElement = containerRef.current.children[
        selectedOption - 1
      ] as HTMLElement;
      if (selectedElement) {
        const containerLeft = containerRef.current.getBoundingClientRect().left;
        const selectedElementLeft =
          selectedElement.getBoundingClientRect().left;
        const vwOffset = (window.innerWidth * 2) / 100;
        const offset = selectedElementLeft - containerLeft - vwOffset;

        containerRef.current.scrollTo({
          left: containerRef.current.scrollLeft + offset,
          behavior: "smooth",
        });
      }
    }
  }, [selectedOption]);

  return (
    <div ref={containerRef} className="progress-tracker">
      {options.map((option, index) => (
        <Box
          key={index}
          marginRight="18px"
          display="inline-block"
          className={index < selectedOption ? "selected-option" : ""}
          onClick={() => (onClick ? onClick(index + 1) : console.log(index))}
        >
          {option}
        </Box>
      ))}
    </div>
  );
};

export default ProgressTracker;
