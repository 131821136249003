// Function to generate the email subject
export function generateEmailSubject(toName: string, fromName: string): string {
  return `${fromName} has requested your feedback on Kydoscope`;
}

// Function to generate the email body text
export function generateEmailText(
  toName: string,
  fromName: string,
  companyNameOrUniversityName: string,
  link: string
): string {
  return `
    <html>
    <body style="font-family: Arial, sans-serif; color: #333; line-height: 1.6;">
      <p>Dear ${toName},</p>

      <p>${fromName} is currently a member of the ${companyNameOrUniversityName} community and values your input. They have requested that you provide your feedback on their performance through Kydoscope, a platform designed for architects and designers to showcase their skills and receive ratings from respected professionals like you.</p>

      <p>Your feedback will play an important role in ${fromName}'s professional journey, and we would greatly appreciate your time and effort.</p>

      <p>To complete the feedback, please click on the following link:</p>
      <p><a href="${link}" style="color: #007BFF; text-decoration: none;">Feedback for ${fromName}</a></p>

      <p>Thank you for contributing to the growth of the design and architecture community.</p>

      <p>Best regards,<br>The Kydoscope Team</p>
    </body>
    </html>
  `;
}
