import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Input,
  Search,
  Next,
  TextVariants,
  Colors,
  Text,
  Button,
  AppRoutes,
  AppLandingPageLogoMobile,
  HeaderLogo,
} from "reusable";
import "./Landing.css";

const Landing = (): JSX.Element => {
  const goTo = useNavigate();

  const handleExploreDesigners = () => goTo(`${AppRoutes.EXPLORE_DESIGNERS}`);

  const [searchedValue, setSearchedValue] = useState<string>("");

  const handleNext = () => {
    goTo(
      `${AppRoutes.EXPLORE_DESIGNERS}?search=${
        searchedValue.length ? searchedValue : ""
      }`
    );
  };

  return (
    <Box className="landing-page">
      <Box display="flex" justifyContent="center">
        <Box className="hide-for-small-only text-center">
          <HeaderLogo />
          <Box marginTop="18.84px">
            <Text
              variant={TextVariants.LARGEST_HEADER}
              className="text-fade-in"
            >
              Home to all <br /> Interior Designers & Architects
            </Text>
            <br />
            <Text
              variant={TextVariants.LARGE_SECONDARY_FONT}
              color={Colors.GRAY_3}
              marginTop="14px"
            >
              Find Verified Designers for your Dream Homes
            </Text>
          </Box>
        </Box>
        <Box className="show-for-small-only">
          <AppLandingPageLogoMobile />
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        className="landing-input-container"
      >
        <Input
          className="landing-input"
          animate
          placeholders={[
            "Search by City",
            "Search by Name",
            "Search by Company",
          ]}
          inputWidth="454px"
          preIcon={
            <Box margin="4px 15px 0 23px" opacity={0.6}>
              <Search />
            </Box>
          }
          postIcon={
            <Box onClick={handleNext} margin="0 10px -5.2px 0" cursor="pointer">
              <Next />
            </Box>
          }
          value={searchedValue}
          onChange={(e) => setSearchedValue(e.target.value)}
          onEnter={handleNext}
        />
      </Box>
      <Box marginBottom="24px" className="app-container">
        <Text variant={TextVariants.MEDIUM_PRIMARY_FONT} color={Colors.GRAY_3}>
          Need Help in Finding a Designer?
        </Text>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          onClick={handleExploreDesigners}
          className="explore-designers-button"
        >
          <Text
            color={Colors.ORANGE}
            variant={TextVariants.SMALL_TERNARY_FONT_BOLD}
          >
            Explore Designers
          </Text>
        </Button>
      </Box>
    </Box>
  );
};

export default Landing;
