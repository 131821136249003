import { Appsettings } from "config";
import { Box, Colors, Text, TextVariants } from "reusable";

const PrivacyPolicy = (): JSX.Element => {
  return (
    <Box className="document-component">
      <Box className="document-component-wrapper">
        <Box width="100%" textAlign="center">
          <Text variant={TextVariants.HEADING_QUARTERNARY_FONT}>
            <Text
              variant={TextVariants.HEADING_QUARTERNARY_FONT}
              color={Colors.ORANGE}
              marginRight="8px"
            >
              {Appsettings.appName}
            </Text>
            Privacy Policy
          </Text>
        </Box>

        <div className="document-content">
          Welcome to KYDOSCOPE VENTURES LLP (&quot;kydoscope.com&quot;). We
          respect your privacy and are committed to protecting your personal
          data. This privacy policy outlines how we collect, use, disclose, and
          safeguard your information when you use our platform, in compliance
          with Indian laws and regulations, including the Information Technology
          Act, 2000 and the allied rules and regulations.
          <br />
          <br />
          We collect various types of personal information to provide and
          improve our services. This includes your name, email address, contact
          number, profile photo, and location. Additionally, we collect
          information about your interactions with our platform, such as the
          pages you visit, the time spent on those pages, and other usage
          statistics. We also use cookies and similar tracking technologies to
          enhance your experience on our platform. Cookies help us understand
          your preferences, customize content, and improve our services.
          <br />
          <br />
          Your personal information is used to create and manage your account,
          facilitate communication, and provide the services offered by our
          platform. We analyse your usage data to understand how you use our
          platform, which helps us improve our services and develop new
          features. We also use your information to personalize your experience
          by suggesting connections or content that may interest you.
          Communication with you is crucial for sending updates, newsletters,
          and other relevant information related to the platform. Furthermore,
          we use your information to comply with legal obligations and protect
          our rights and interests.
          <br />
          <br />
          We partner with third-party service providers to enhance our
          platform&#39;s functionality and security. For instance, we use
          third-party hosting services to store and manage data securely. If you
          make purchases through our platform, your payment information is
          processed by a third-party payment gateway. We may share your
          information with third-party vendors who provide additional services,
          such as analytics, customer support, and marketing assistance. These
          third parties are bound by strict confidentiality agreements and are
          only allowed to use your information as necessary to provide these
          services.
          <br />
          <br />
          We implement robust security practices and procedures to protect your
          personal data from unauthorized access, use, or disclosure. Despite
          our efforts, no data transmission over the internet or electronic
          storage can be guaranteed to be completely secure. Therefore, we
          cannot ensure or warrant the absolute security of any information you
          transmit to us.
          <br />
          <br />
          We retain your personal data only as long as necessary to fulfill the
          purposes outlined in this policy, unless a longer retention period is
          required or permitted by law. Once your data is no longer needed, we
          take appropriate measures to anonymize or securely delete it.
          <br />
          <br />
          You have several rights regarding your personal data. You can access
          and update your personal information held by us at any time through
          your account settings. If you wish to withdraw your consent for the
          processing of your personal data, you can do so at any time. Please
          note that the withdrawal of consent will not affect the lawfulness of
          processing based on consent before its withdrawal. Additionally, you
          may request the deletion of your personal data, subject to legal and
          contractual obligations. To exercise any of these rights, please
          contact us at the provided contact information.
          <br />
          <br />
          We may update this privacy policy from time to time to reflect changes
          in our practices or applicable laws. We will notify you of any
          significant changes by posting the new policy on our platform and
          updating the &quot;Last Updated&quot; date at the top of this policy.
          We encourage you to review this policy periodically to stay informed
          about how we protect your information.
          <br />
          <br />
          If you have any questions or concerns about this privacy policy or our
          data practices, please contact us at{" "}
          <a style={{ color: Colors.ORANGE }} href="mailto:info@kydoscope.com">
            info@kydoscope.com
          </a>
          .<br />
          <br />
          By using our platform, you consent to the collection, use, and
          disclosure of your information as described in this privacy policy.
          Thank you for being a part of our community!
        </div>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
