export const softwares = [
  "AutoCAD",
  "Revit",
  "SketchUp",
  "3ds Max",
  "Rhino",
  "ArchiCAD",
  "Vectorworks",
  "Lumion",
  "V-Ray",
  "Enscape",
  "Chief Architect",
  "Photoshop",
  "Illustrator",
  "InDesign",
  "Lightroom",
  "Dialux",
  "Kerkythea",
  "Grasshopper",
  "Navisworks",
  "BIMx",
  "Maya",
  "Blender",
  "Rendering",
  "Model Viewer",
  "SculptGL",
  "Adobe XD",
  "Fusion 360",
  "SolidWorks",
  "InfraWorks",
  "Bluebeam Revu",
  "Adobe Acrobat",
  "FormIt",
  "CityEngine",
  "PlanGrid",
  "Archicad BIM Server",
  "Collaboration for Revit",
  "DocuSign",
  "Trello",
  "Asana",
  "Microsoft Project",
  "Evernote",
  "Basecamp",
  "Notion",
  "Slack",
  "Zoom",
  "TeamViewer",
  "Dropbox",
  "Google Drive",
  "OneDrive",
  "SharePoint",
  "AutoCAD Architecture",
  "Autodesk BIM 360",
  "Roomstyler 3D Home Planner",
  "Sweet Home 3D",
  "Live Home 3D",
  "Space Designer 3D",
  "Planner 5D",
  "SmartDraw",
  "SketchUp Layout",
  "Chief Architect Home Designer",
  "SoftPlan",
  "Cadsoft Envisioneer",
  "MicroStation",
  "OpenStudio",
  "OpenBIM",
  "Revit LT",
  "Rhinoceros",
  "Grasshopper for Rhino",
  "The Wild",
  "Fuzor",
  "Datamine",
  "iRhino",
  "Houdini",
  "Archicad SOLO",
  "Confluence",
  "Wunderlist",
  "Todoist",
  "Quire",
  "Miro",
  "MindMeister",
  "Mural",
  "Architectural Desktop",
  "SketchUp Pro",
  "Revit MEP",
  "Revit Structure",
  "Revit Families",
  "GIMP",
  "Draftworx",
  "ConceptDraw",
  "EPLAN",
  "Procreate",
  "Autodesk A360",
  "Onshape",
];
