import { useState } from "react";
import { Box, Button, Colors, Rating, Text, TextVariants } from "reusable";
import { FeedbackQuestion } from "models";
import { Appsettings } from "config";

export const FeedbackTile = (props: {
  question: FeedbackQuestion;
  setUserRating: (rating: number) => void;
  userRating: number;
}): JSX.Element => {
  const { order, question } = props.question;
  const { userRating, setUserRating } = props;
  return (
    <Box className="feedback-tile">
      <Box className="feedback-question-container">
        <Text variant={TextVariants.LARGE_SECONDARY_FONT} color={Colors.GRAY_3}>
          {`Q${order}.`}
        </Text>
        <Box marginTop="8px">
          <Text variant={TextVariants.EXTRA_LARGE_TERNARY_FONT_BOLD}>
            {question}
          </Text>
        </Box>
      </Box>
      <Box className="feedback-ratings-container">
        <Rating
          totalStars={Appsettings.totalRating}
          filledStars={userRating}
          onClick={(givenRating) => setUserRating(givenRating)}
        />
        <Text
          marginTop="18px"
          variant={TextVariants.LARGE_SECONDARY_FONT}
          color={Colors.GRAY_3}
        >{`${userRating} / ${Appsettings.totalRating} Stars`}</Text>
      </Box>
    </Box>
  );
};
