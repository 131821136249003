import { CSSProperties } from "react";
import { Colors, TextVariants } from "reusable";
import { Box } from "../box";
import { Text } from "../text";
import "./Chip.css";

const Chip = (props: {
  title: string;
  marginRight?: string;
  marginBottom?: string;
}): JSX.Element => {
  const { title, marginRight, marginBottom } = props;
  return (
    <Box
      className="chip-component"
      marginRight={marginRight}
      marginBottom={marginBottom}
    >
      <Text
        color={Colors.BLACK_DARK}
        variant={TextVariants.MEDIUM_TERNARY_FONT_BOLD}
      >
        {title}
      </Text>
    </Box>
  );
};

export default Chip;
