import { useState } from "react";
import Autocomplete from "./Autocomplete";
import { worldLanguages } from "reusable";

const LanguageAutocomplete = (props: {
  input: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}): JSX.Element => {
  const { onChange, input } = props;
  const [suggestions, setSuggestions] = useState<Array<string>>([]);

  const onInputChange = (value: string) => {
    if (value !== undefined) {
      const selectedLanguages = value?.split(", ");
      const searchTerm = (
        (selectedLanguages as any)?.pop() || value
      )?.toLowerCase();
      onChange({
        target: { value: value },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
      setSuggestions(
        value
          ? worldLanguages.filter(
              (l) =>
                l?.toLowerCase().includes(searchTerm) &&
                !selectedLanguages?.includes(l)
            )
          : []
      );
    }
  };

  const onLanguageSelect = (value: string) => {
    if (value) {
      const selectedValues = input?.split(", ");
      selectedValues?.pop();
      const filteredInput = selectedValues?.join(", ");
      setSuggestions([]);
      onChange({
        target: {
          value:
            selectedValues?.length > 0
              ? filteredInput + ", " + value + ", "
              : value + ", ",
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <Autocomplete
      suggestions={suggestions}
      onChange={onInputChange}
      onSelect={onLanguageSelect}
      userInput={input}
      placeholder="Type Languages"
      onEnter={() => console.log("")}
    />
  );
};

export default LanguageAutocomplete;
