import React from "react";
import { ButtonProps } from "./ButtonProps";
import "./Button.css";

const Button = ({
  children = "Button",
  onClick,
  backgroundColor,
  color,
  width,
  height,
  borderRadius,
  border,
  className,
  disabled = false,
}: ButtonProps): JSX.Element => {
  const style = {
    backgroundColor,
    width,
    height,
    borderRadius,
    border,
    disabled,
    color,
  };
  const disabledClass = disabled ? "disabled" : "";

  return (
    <button
      className={`button-component ${className} ${disabledClass}`}
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
