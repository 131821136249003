// errorMappings.ts
interface FirebaseErrorType {
  code: string;
  message: string;
}
const firebaseErrorMessages: { [key: string]: string } = {
  "auth/user-not-found":
    "The email address you entered does not belong to an account. Please check your email address and try again.",
  "auth/wrong-password":
    "The password you entered is incorrect. Please try again or reset your password.",
  "auth/email-already-in-use":
    "Another account is using this email address. Try another email or log in.",
  "auth/weak-password":
    "Your password is too weak. Please choose a stronger password.",
  "auth/network-request-failed":
    "We couldn't connect to the network. Please check your connection and try again.",
  "auth/invalid-email":
    "You've entered an invalid email address. Please check it and try again.",
  "auth/user-disabled":
    "Your account has been disabled. If you think this is a mistake, please contact support.",
  "auth/too-many-requests":
    "We've detected too much activity on your account. Please wait a while before trying again.",
  "auth/operation-not-allowed":
    "This operation isn't allowed. Contact support if you think this is a mistake.",
  "auth/account-exists-with-different-credential":
    "An account with this email address already exists. Please log in with that email to continue.",
  "auth/invalid-credential":
    "Your login information seems to be invalid. Please log in again.",
  "auth/invalid-verification-code":
    "The verification code is incorrect. Please check and try again.",
  "auth/invalid-verification-id":
    "We couldn't verify your code. Please try to resend it.",
  "auth/requires-recent-login":
    "Please log in again to continue with this operation.",
  "auth/provider-already-linked": "This account is already linked.",
  "auth/no-such-provider":
    "The requested service isn't available for your account.",
  "auth/credential-already-in-use":
    "These credentials are already associated with another account.",
  "auth/timeout": "Your request has timed out. Please try again.",
  "auth/missing-phone-number": "Please enter your phone number.",
  "auth/quota-exceeded": "You've reached your limit. Please try again later.",
  "auth/captcha-check-failed":
    "We couldn't verify that you're not a robot. Please try again.",
  "auth/invalid-phone-number":
    "You've entered an invalid phone number. Please check it and try again.",
  "auth/missing-verification-code": "Please enter the verification code.",
  "auth/missing-verification-id": "Verification failed. Please try again.",
  "auth/session-cookie-expired":
    "Your session has expired. Please log in again.",
  "auth/session-cookie-revoked":
    "Your session has been revoked. Please log in again.",
  "auth/internal-error":
    "Something went wrong on our end. Please try again later.",
  "auth/popup-closed-by-user":
    "You closed the sign-in window before we could finish. Please try again and ensure the window remains open to complete your sign-in.",
  "auth/popup-blocked":
    "The sign-in window was blocked by your browser. Please allow popups for this site and try signing in again.",
  default: "An unexpected error occurred. Please try again.",
};

export function getFriendlyErrorMessage(error: unknown): string {
  if (typeof error === "object" && error !== null && "code" in error) {
    const firebaseError = error as FirebaseErrorType;
    return (
      firebaseErrorMessages[firebaseError?.code] ||
      firebaseErrorMessages["default"]
    );
  }
  return firebaseErrorMessages["default"];
}
