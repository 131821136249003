import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CreatorProfile, ProfileSummary as ProfileSummaryModel } from "models";
import {
  AppRoutes,
  Avatar,
  Box,
  Button,
  Colors,
  LOCAL_STORAGE_KEYS,
  Link,
  RoleDisplayer,
  TextVariants,
  Text,
  PremiumBadge,
  dummyRatings,
  SummaryTileStar,
  ShareProfile,
  dummyUserId,
  Chip,
  Menu,
  Dropdown,
} from "reusable";
import { useUser, useLocalStorage } from "backend";
import PhotoSection from "./PhotosSection";

const ProfileTile = (props: {
  profile: CreatorProfile;
  isPreview?: boolean;
}): JSX.Element => {
  const { profile, isPreview } = props;
  const goTo = useNavigate();
  const { loggedInUser, logout } = useUser();
  const { isMobileScreen } = useLocalStorage();
  const {
    companyName,
    completedProjects,
    experienceYears,
    projectCost,
    projectType,
    languages,
    about,
    websiteUrl,
    instagramLink,
    profilePicture = "",
    occupation,
    name,
    location,
    isSubscribed = false,
    ratings,
    userId,
    position,
    graduationYear,
    softwareSkills,
    theme,
    universityName,
    contactNumber,
  } = profile;

  const isOwner = position === "Business Owner";
  const [isWorkGalleryOpen, setIsWorkGalleryOpen] = useState<boolean>(true);
  const profileStats = isOwner
    ? [
        {
          label: "Projects",
          value: completedProjects,
        },
        {
          label: "Avg. Cost",
          value:
            String(projectCost)?.toLowerCase().includes("lakh") ||
            String(projectCost) === "-" ||
            String(projectCost)?.toLowerCase().includes("lac")
              ? String(projectCost)
              : `${String(projectCost) || 0} Lakhs`,
        },
        {
          label: "Years Exp.",
          value: experienceYears,
        },
      ]
    : [];

  const isStudent = position === "Student";
  const profileDetails = isOwner
    ? [
        { label: "About", value: about },
        { label: "Registered Company", value: companyName },
        { label: "Project Type", value: projectType },
        { label: "Languages", value: languages || [] },
      ]
    : [
        { label: "About", value: about },
        { label: "Work Experience", value: experienceYears },
        {
          label: isStudent
            ? "University/Institution"
            : "Current/Previous Company",
          value: isStudent ? universityName : companyName,
        },
        {
          label: "Graduation Year",
          value: graduationYear,
        },

        { label: "Languages", value: languages || [] },
        { label: "Software Skills", value: softwareSkills || "" },
      ];

  const profileLinks = [
    { label: "Website", value: websiteUrl },
    { label: "Instagram", value: instagramLink },
  ];

  const businessOwnerQuestions = [
    "occupation",
    "position",
    "firstName",
    "lastName",
    "contactNumber",
    "location",
    "profilePicture",
    "about",
    "languages",
    "experienceYears",
    "completedProjects",
    "projectCost",
    "projectType",
    "creatorPhotos",
  ];

  const businessPremiumQuestions = [
    "websiteUrl",
    "instagramLink",
    "isHiring",
    "theme",
  ];

  const finalBusinessQuestions = [
    ...businessOwnerQuestions,
    ...(profile?.isSubscribed ? businessPremiumQuestions : []),
  ];

  const nonBusinessQuestions = [
    "occupation",
    "position",
    "firstName",
    "lastName",
    "contactNumber",
    "location",
    "profilePicture",
    "about",
    "languages",
    "experienceYears",
    "companyName",
    "graduationYear",
    "softwareSkills",
    "creatorPhotos",
  ];

  const nonBusinessPremiumQuestions = [
    "websiteUrl",
    "instagramLink",
    "lookingForJob",
    "theme",
  ];

  const finalNonBusinessQuestions = [
    ...nonBusinessQuestions,
    ...(profile?.isSubscribed ? nonBusinessPremiumQuestions : []),
  ];

  const canEdit = loggedInUser?.userId === profile?.userId;

  useEffect(() => {
    if (isMobileScreen) {
      setIsWorkGalleryOpen(true);
    } else {
      setIsWorkGalleryOpen(false);
    }
  }, [isMobileScreen]);

  const { overall, service, design, delivery, totalRatings } =
    ratings || dummyRatings;

  const userRatings = [
    { label: "Overall", value: overall },
    { label: "Service", value: service },
    { label: "Design", value: design },
    { label: "Delivery", value: delivery },
  ];

  const getProfileUrl = () => {
    const { protocol, host, pathname } = window.location;
    return `${protocol}//${host}${pathname}`;
  };

  const handleEditOrFeedback = () => {
    if (canEdit) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.STEP_IN_PROGRESS, "3");
    }
    goTo(
      canEdit
        ? AppRoutes.FILL_PROFILE_DETAILS.replace(
            ":userId",
            userId || dummyUserId
          )
        : AppRoutes.FEEDBACK.replace(":userId", userId || dummyUserId)
    );
  };

  const getVerifiedFeedback = () => {
    goTo(AppRoutes.GET_RATING.replace(":userId", userId));
  };

  const tags = [occupation?.split(", ")[0] || occupation, position];

  return (
    <Box className="profile-tile-container">
      <Box className="profile-tile-1">
        <Box
          className="profile-tile-summary-role"
          background={isSubscribed ? theme?.background || "" : ""}
        >
          <Box padding="43px 0 0 29px">
            <Avatar
              url={profilePicture}
              componentKey={String(profilePicture)}
              expand={isMobileScreen}
              name={name}
            />
          </Box>

          <Box padding="11px 20px 6px 10px" textAlign="right">
            <RoleDisplayer
              name={tags[0]}
              theme={theme?.color ?? "red-displayer"}
              color={theme?.color}
            />
            <Box marginTop="23px">
              <Box className="position-at">
                {position === "Employee" ? "Works at " : position + " at "}
                <span style={{ fontWeight: "600" }}>
                  {" "}
                  {companyName || universityName}
                </span>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="profile-tile-details">
          <Box className="name-details" borderBottom="1px #e5e7eb solid">
            <Box display="flex" alignItems="center" marginBottom="5px">
              <Text
                color={Colors.BLACK_MAIN}
                variant={TextVariants.DOUBLE_LARGE_TERNARY_FONT_BOLD}
                ellipsis
              >
                {name}
              </Text>
              {isSubscribed && (
                <span className="premium-badge">
                  <PremiumBadge />
                </span>
              )}
            </Box>
            <Text
              display="block"
              color={Colors.GRAY_4}
              variant={TextVariants.MEDIUM_PRIMARY_FONT}
            >
              {location?.place}
            </Text>
          </Box>
          <Box className="rating-details">
            <Box className="rating-details-header">
              <Box marginRight="8px" display="inline-block">
                <SummaryTileStar />
              </Box>
              <Text
                variant={TextVariants.LARGE_TERNARY_FONT_BOLD_600}
                color={Colors.BLACK_DARK}
              >
                {isOwner
                  ? "Rating"
                  : position === "Student"
                  ? "Professor Rating"
                  : "Employer Rating"}
              </Text>
              <Text
                color={Colors.GRAY_4}
                variant={TextVariants.MEDIUM_PRIMARY_FONT}
                marginLeft="8px"
              >
                {totalRatings ? `(${totalRatings})` : "No Ratings Yet"}
              </Text>
            </Box>
            <Box className="profile-stats">
              {userRatings.map((rating) => (
                <Box
                  key={rating.label}
                  className="stat-box"
                  display="inline-block"
                >
                  <Box className="state-box-value">
                    {rating.value === 0
                      ? "-"
                      : Math.round(rating.value * 10) / 10}
                    {rating.value !== 0 && (
                      <Text
                        color={Colors.GRAY_5}
                        variant={TextVariants.MEDIUM_PRIMARY_FONT_BOLD}
                        marginLeft="1.5px"
                      >
                        /5
                      </Text>
                    )}
                  </Box>
                  <Box className="state-box-label">{rating.label}</Box>
                </Box>
              ))}
            </Box>
          </Box>
          {/* <Box className="profile-tile-buttons">
            {canEdit ||
              isOwner ||
              (profile?.feedbackRequests?.includes(
                loggedInUser?.email || ""
              ) && (
                <Button
                  className="profile-tile-button-1"
                  // Remove !canEdit to enable feedback option
                  disabled={isPreview}
                  onClick={handleEditOrFeedback}
                >
                  {canEdit ? "Edit" : "Give Feedback"}
                </Button>
              ))}
            <ShareProfile
              profileUrl={getProfileUrl()}
              isPreview={Boolean(isPreview)}
              userName={name}
            />
          </Box> */}
          <Box className="profile-tile-buttons">
            {isOwner && (
              <Button
                className="profile-tile-button-1"
                // Remove !canEdit to enable feedback option
                disabled={isPreview}
                onClick={handleEditOrFeedback}
              >
                {canEdit ? "Edit" : "Give Feedback"}
              </Button>
            )}
            {!isOwner && canEdit && (
              <Button
                className="profile-tile-button-1"
                // Remove !canEdit to enable feedback option
                disabled={isPreview}
                onClick={getVerifiedFeedback}
                width="153px"
              >
                Get Verified Rating
              </Button>
            )}
            <ShareProfile
              profileUrl={getProfileUrl()}
              isPreview={Boolean(isPreview)}
              userName={name}
              title={isOwner ? "Share Profile" : "Share"}
            />
            {!isOwner && canEdit && (
              <Dropdown
                title={<Menu />}
                options={[
                  {
                    title: "Edit",
                    onClick: handleEditOrFeedback as unknown as void,
                  },
                  {
                    title: "Logout",
                    onClick: logout as unknown as void,
                  },
                ]}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box className="profile-tile-2">
        <Box className="profile-buttons show-for-small-only">
          <Button
            className={`profile-button ${
              isWorkGalleryOpen ? "profile-button-selected" : ""
            }`}
            onClick={() => setIsWorkGalleryOpen(true)}
          >
            Work Gallery
          </Button>
          <Button
            className={`profile-button ${
              !isWorkGalleryOpen ? "profile-button-selected" : ""
            }`}
            onClick={() => setIsWorkGalleryOpen(false)}
          >
            About
          </Button>
        </Box>
        {isWorkGalleryOpen ? (
          <Box className="show-for-small-only" padding="0 10px">
            <PhotoSection urls={profile?.creatorPhotos} />
          </Box>
        ) : (
          <Box padding="0 32px">
            <Box className="profile-stats">
              {profileStats.map((stat) => (
                <Box className="stat-box" display="inline-block">
                  <Box className="state-box-value">{stat.value || 0}</Box>
                  <Box className="state-box-label">{stat.label}</Box>
                </Box>
              ))}
            </Box>
            <Box className="profile-details">
              {profileDetails.map((detail) => (
                <Box className="profile-detail">
                  <Box className="profile-detail-label">{detail.label}</Box>
                  <Box className="profile-detail-value">
                    {detail.label === "Languages"
                      ? (detail as any)?.value?.split(",").map((v: string) => {
                          return v.length > 1 ? (
                            <Chip
                              title={v}
                              marginRight="12px"
                              marginBottom="10px"
                            />
                          ) : (
                            <></>
                          );
                        })
                      : detail.label === "Software Skills"
                      ? (detail as any)?.value?.split(",").map((v: string) => {
                          return v.length > 1 ? (
                            <Chip
                              title={v}
                              marginRight="12px"
                              marginBottom="10px"
                            />
                          ) : (
                            <>
                              {(detail as any)?.value?.split(",")?.length > 0
                                ? ""
                                : "N/A"}
                            </>
                          );
                        })
                      : detail.value || "N/A"}
                  </Box>
                </Box>
              ))}
            </Box>
            {isSubscribed && (
              <Box>
                <Box className="profile-detail-label">Contact Number</Box>{" "}
                <Box className="profile-detail-value">{contactNumber}</Box>
              </Box>
            )}
            {isSubscribed && profileLinks[0]?.value?.length && (
              <Box className="profile-links-section">
                <Box className="profile-detail-label">Links</Box>
                {profileLinks.map((link: any) => (
                  <Box display="inline-block" marginRight="10px">
                    <Link
                      onClick={() =>
                        window.open(
                          `${
                            link?.value.includes("http")
                              ? link.value
                              : `https://${link?.value}`
                          }`,
                          "_blank"
                        )
                      }
                    >
                      {link?.value && link?.value !== "-" && (
                        <Box>
                          <Box
                            display="inline-block"
                            className="profile-links-value"
                          >
                            {link?.label}
                          </Box>
                          <Box marginLeft="8px" display="inline-block">
                            <svg
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.5382 12.2986L14.5382 4.46152M14.5382 4.46152L6.70108 4.46152M14.5382 4.46152L4.46191 14.5378"
                                stroke="#2563EB"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </Box>
                        </Box>
                      )}
                    </Link>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProfileTile;
