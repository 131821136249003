import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUser, useCreator, useAlerts } from "backend";
import {
  AppRoutes,
  Box,
  Button,
  Colors,
  FeedbackQuestions,
  FeedbackSuccess,
  ProfileSummary,
  dummySummary,
} from "reusable";

import "./Feedback.css";
import {
  Feedback as FeedbackModel,
  ProfileSummary as ProfileSummaryModel,
} from "models";
import { FeedbackTile } from "./FeedbackTile";
import { firestoreTimestamp } from "config";

const Feedback = (): JSX.Element => {
  //import
  const { loggedInUser } = useUser();
  const { userId } = useParams();
  const {
    giveFeedback,
    isFeedbackLoading,
    getFeedback,
    oldFeedback,
    getProfile,
    creatorProfile,
    isLoading,
  } = useCreator();
  const goTo = useNavigate();
  const { addAlert } = useAlerts();

  //states
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] =
    useState<boolean>(false);
  const [ratings, setRatings] = useState<Array<Record<string, number>>>([]);

  const handleAfterFeedback = () => {
    setIsFeedbackSubmitted(true);
    setRatings([]);
  };

  const handleContinueButton = () => {
    const summaryId = creatorProfile?.userId;
    const userId = loggedInUser?.userId;

    if (summaryId && userId) {
      const index = currentQuestionIndex + 1;
      if (index === FeedbackQuestions.length) {
        giveFeedback(
          {
            by: userId,
            to: summaryId,
            ratings: ratings,
            comment: "",
            timestamp: firestoreTimestamp,
          },
          oldFeedback || ({} as FeedbackModel),
          handleAfterFeedback
        );
      } else {
        setCurrentQuestionIndex(index);
      }
    }
  };

  const handleCancel = () => {
    setCurrentQuestionIndex(0);
    setRatings([]);
    goTo(AppRoutes.PROFILE.replace(":userId", userId || "userId"));
  };

  const setUserRating = (rating: number) => {
    const { questionId, parentId } = FeedbackQuestions[currentQuestionIndex];
    const newRating = { questionId, rating, parentId };
    setRatings(
      (ratings?.length > currentQuestionIndex
        ? ratings?.map((rating, id) =>
            id === currentQuestionIndex ? { ...newRating } : rating
          )
        : [...ratings, newRating]) as any
    );
  };

  //effects
  useEffect(() => {
    if (userId === loggedInUser?.userId) {
      goTo(
        AppRoutes.PROFILE.replace(":userId", loggedInUser?.userId || "userId")
      );
    } else if (userId) {
      getProfile(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      getFeedback(loggedInUser?.userId || "", userId || "");
    }
    if (
      creatorProfile?.position !== "Business Owner" &&
      !creatorProfile?.feedbackRequests?.some(
        (req) =>
          req?.emailId?.toLowerCase() === loggedInUser?.email?.toLowerCase()
      ) &&
      creatorProfile
    ) {
      addAlert({
        message:
          "Please log in using the same email address where you received this link",
        type: "error",
        autoHide: false,
        displayCross: false,
      });
    }
  }, [creatorProfile, userId]);

  useEffect(() => {
    if (oldFeedback?.ratings) {
      setRatings(oldFeedback?.ratings as any);
    }
  }, [oldFeedback]);

  const summary = (
    isLoading ? dummySummary : creatorProfile
  ) as ProfileSummaryModel;

  if (
    creatorProfile?.position !== "Business Owner" &&
    !creatorProfile?.feedbackRequests?.some(
      (req) => req?.emailId === loggedInUser?.email
    ) &&
    creatorProfile
  ) {
    return <></>;
  }

  return (
    <Box className="align-center">
      <Box
        className="feedback-container"
        showSkeleton={isLoading}
        isLoading={isLoading || isFeedbackLoading}
      >
        <Box className="grid-x">
          <Box className="cell small-12 medium-shrink profile-tile-feedback-container">
            <ProfileSummary summary={summary} feedbackMode />
          </Box>
          <Box className="cell small-12 medium-auto feedback-tile-container">
            {isFeedbackSubmitted ? (
              <Box>
                <FeedbackSuccess />
                <Button
                  className="transparent-button feedback-complete-button"
                  backgroundColor={Colors.BLACK_DARK}
                  color={Colors.WHITE}
                  onClick={() =>
                    goTo(
                      AppRoutes.PROFILE.replace(":userId", userId || "userId")
                    )
                  }
                >
                  Go to Profile
                </Button>
              </Box>
            ) : (
              <FeedbackTile
                userRating={
                  (ratings[currentQuestionIndex]?.rating as number) || 0
                }
                setUserRating={setUserRating}
                question={FeedbackQuestions[currentQuestionIndex]}
              />
            )}

            {!isFeedbackSubmitted && (
              <Box className="feedback-button-container">
                <Button
                  className="transparent-button"
                  backgroundColor={Colors.BLACK_DARK}
                  color={Colors.WHITE}
                  onClick={handleContinueButton}
                  disabled={
                    ((ratings[currentQuestionIndex]?.rating as number) || -1) <
                      0 && !isFeedbackSubmitted
                  }
                >
                  Continue
                </Button>
                <Box marginTop="22px">
                  <Button className="transparent-button" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Feedback;
