// UserService.ts
import { FirebaseService } from "../firebase/firebase-service";
import { Unsubscribe, User, onAuthStateChanged } from "firebase/auth";
import {
  CreatorQuestionIds,
  USER_ROLES,
  capitalize,
  generateSearchTerms,
  isWIthoutUrl,
  searchFields,
} from "reusable";
import { FIRESTORE_KEYS } from "../keys";
import { auth } from "config";
import { Alert, CreatorProfile, LoggedInUser, ProfileSummary } from "models";
import { getFriendlyErrorMessage } from "../firebase/error-mapping";

export const UserService = {
  async signUpUser(
    email: string,
    password: string,
    name: string,
    handleResponse: (user: User) => void,
    handleError: (alert: Alert) => void,
    additionalData?: Record<string, any>
  ) {
    const userCredential = await FirebaseService.createUserWithEmailAndPass(
      email,
      password
    );
    if ("type" in userCredential) {
      handleError(userCredential as Alert);
      return;
    }
    const user = userCredential;
    const updateProfileResult = await FirebaseService.updateUserProfile(
      user,
      capitalize(name)
    );
    if (updateProfileResult && "type" in updateProfileResult) {
      handleError(updateProfileResult as Alert);
      return;
    }
    const setDocResult = await FirebaseService.setFirestoreDoc(
      FIRESTORE_KEYS.USER_COLLECTION,
      user.uid,
      {
        ...additionalData,
        userId: user.uid,
      }
    );
    if (setDocResult && "type" in setDocResult) {
      handleError(setDocResult as Alert);
      return;
    }
    handleResponse(user);
  },

  async signInWithGoogle(
    handleResponse: (user: User, isNew: boolean) => void,
    handleError: (alert: Alert) => void,
    additionalData?: Record<string, any>
  ) {
    const result = await FirebaseService.signInWithGoogle();
    if ("type" in result) {
      handleError(result as Alert);
      return;
    }
    const user = result;
    const metadata = user.metadata;
    const isNewUser = metadata.creationTime === metadata.lastSignInTime;
    if (isNewUser) {
      const updateProfileResult = await FirebaseService.updateUserProfile(
        user,
        capitalize(user.displayName || "")
      );
      if (updateProfileResult && "type" in updateProfileResult) {
        handleError(updateProfileResult as Alert);
        return;
      }
      const setDocResult = await FirebaseService.setFirestoreDoc(
        FIRESTORE_KEYS.USER_COLLECTION,
        user.uid,
        {
          ...additionalData,
          userId: user.uid,
          email: user.email,
          name: capitalize(user.displayName || ""),
        }
      );
      if (setDocResult && "type" in setDocResult) {
        handleError(setDocResult as Alert);
        return;
      }
    }
    handleResponse(user, isNewUser as boolean);
  },

  async loginUser(
    email: string,
    password: string,
    handleResponse: (user: User) => void,
    handleError: (alert: Alert) => void
  ) {
    const userCredential = await FirebaseService.signInWithEmailAndPass(
      email,
      password
    );
    if ("type" in userCredential) {
      handleError(userCredential as Alert);
      return;
    }
    handleResponse(userCredential);
  },

  subscribeToAuthChanges: (
    callback: (user: User | null) => void
  ): Unsubscribe => {
    return onAuthStateChanged(auth, callback);
  },

  async publishUserProfile(
    profileData: CreatorProfile,
    handleSuccess: (profileData: CreatorProfile) => void,
    handleError: (alert: Alert) => void,
    isEditing?: boolean
  ) {
    //first level checking for user id
    if (!profileData?.userId) {
      handleError({
        message: getFriendlyErrorMessage(""),
        type: "error",
      });
      return;
    }

    // Uploading Photos to DB
    // Checking with isWithoutUrl which confirms if payload includes filetype only
    let newCreatorPhotoUrls = [];
    if (profileData?.newCreatorImages?.length) {
      newCreatorPhotoUrls = (await FirebaseService.uploadFilesAndRetrieveURLs(
        profileData.userId,
        profileData?.newCreatorImages
      )) as any;
    }

    const oldPhotosUrls =
      profileData?.creatorPhotos?.filter(
        (url: string) =>
          !(profileData?.removedUrls || [])?.includes(url) &&
          url?.includes("firebasestorage")
      ) || [];
    const updatedUrls = [...newCreatorPhotoUrls, ...oldPhotosUrls];

    // Uploading Profile Picture to DB

    let profilePictureUrl = (profileData?.profilePicture as string) || "";

    if (profileData?.newProfilePicture?.length) {
      profilePictureUrl = (
        (await FirebaseService.uploadFilesAndRetrieveURLs(
          profileData.userId,
          profileData?.newProfilePicture
        )) as any
      )[0];
    }
    if (
      (newCreatorPhotoUrls &&
        typeof newCreatorPhotoUrls === "object" &&
        "type" in newCreatorPhotoUrls) ||
      (profilePictureUrl &&
        typeof profilePictureUrl === "object" &&
        "type" in profilePictureUrl)
    ) {
      handleError(newCreatorPhotoUrls as Alert);
      return;
    }

    profileData.creatorPhotos = updatedUrls;
    profileData.profilePicture = profilePictureUrl;

    const { newCreatorImages, newProfilePicture, removedUrls, ...finalData } =
      profileData;

    // Updating Creator Profiles Collection
    const setDocResult = await FirebaseService.setFirestoreDoc(
      FIRESTORE_KEYS.CREATOR_PROFILES_COLLECTION,
      profileData.userId,
      finalData
    );

    if (setDocResult && "type" in setDocResult) {
      handleError(setDocResult as Alert);
      return;
    }

    const summary = {
      userId: profileData.userId,
      [CreatorQuestionIds.OCCUPATION]:
        profileData[CreatorQuestionIds.OCCUPATION],
      [CreatorQuestionIds.NAME]: profileData[CreatorQuestionIds.NAME],
      [CreatorQuestionIds.WHERE_ARE_YOU_LOCATED]:
        profileData[CreatorQuestionIds.WHERE_ARE_YOU_LOCATED],
      [CreatorQuestionIds.PROFILE_PICTURE]:
        profileData[CreatorQuestionIds.PROFILE_PICTURE],
      creationTime: profileData?.creationTime,
      position: profileData?.position,
      ratings: {
        overall: profileData?.ratings?.overall || 0,
        service: profileData?.ratings?.service || 0,
        design: profileData?.ratings?.design || 0,
        delivery: profileData?.ratings?.delivery || 0,
      },
    };

    const setSummaryResult = await FirebaseService.setFirestoreDoc(
      FIRESTORE_KEYS.CREATOR_PROFILES_SUMMARY,
      profileData.userId,
      {
        ...summary,
        searchTerms: generateSearchTerms(searchFields, {
          ...profileData,
          location: profileData?.location?.place,
        }),
      }
    );

    if (setSummaryResult && "type" in setSummaryResult) {
      handleError(setSummaryResult as Alert);
      return;
    }

    // Profile Creation Completed.
    const updateDocResult = await FirebaseService.updateFirestoreDoc(
      FIRESTORE_KEYS.USER_COLLECTION,
      profileData.userId,
      {
        isCreatorQuestionsAnswered: true,
        role: USER_ROLES.CREATOR,
        profilePicture: profilePictureUrl,
        contactNumber: profileData?.contactNumber,
        occupation: profileData?.occupation,
        location: profileData?.location,
        name: profileData?.name,
      }
    );
    if (updateDocResult && "type" in updateDocResult) {
      handleError(updateDocResult as Alert);
      return;
    }
    handleSuccess(profileData);
  },

  async getLoggedInUser(
    userId: string,
    handleResponse: (user: LoggedInUser) => void,
    handleError: (alert: Alert) => void
  ) {
    const user = await FirebaseService.getFirestoreDoc(
      FIRESTORE_KEYS.USER_COLLECTION,
      userId
    );
    if ("type" in user) {
      handleError(user as Alert);
      return;
    }
    handleResponse(user as LoggedInUser);
  },

  async logout(callback: () => void, handleError: (alert: Alert) => void) {
    try {
      await auth.signOut();
      callback();
    } catch (error) {
      handleError({ message: "Logout failed.", type: "error" });
    }
  },
};
