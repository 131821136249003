import { Box, Email, PasswordEyeOpen, PasswordEyeClosed } from "reusable";
import SignUpInput from "./SignUpInput";
import { useState } from "react";

const Credentials = (props: {
  handleChange: (field: string, value: string) => void;
  userData: Record<string, string>;
  loginMode?: boolean;
}): JSX.Element => {
  const { handleChange, userData, loginMode = false } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const inputs = [
    {
      title: "Name",
      placeholder: "Full Name",
      value: userData.name,
      type: "text",
      display: !loginMode,
    },
    {
      title: "Email",
      placeholder: "your@email.com",
      preIcon: <Email />,
      value: userData.email,
      type: "email",
      display: true,
    },
    {
      title: "Password",
      placeholder: "• • • • • • • •",
      postIcon: (
        <Box onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <PasswordEyeClosed /> : <PasswordEyeOpen />}
        </Box>
      ),
      value: userData.password,
      type: showPassword ? "text" : "password",
      display: true,
    },
  ];

  return (
    <Box id="credentials" marginTop="26.5px" className="credentials-container">
      {inputs
        .filter((i) => i.display)
        .map((input, index) => (
          <SignUpInput
            onChange={(e) =>
              handleChange(input.title.toLowerCase(), e.target.value)
            }
            key={index}
            {...input}
          />
        ))}
    </Box>
  );
};

export default Credentials;
