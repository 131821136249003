type FeedbackQuestion = {
  parentId: string;
  questionId: string;
  question: string;
  isRequired: boolean;
  order: number;
  weightage: number;
};

type UserRating = {
  questionId: string;
  rating: number;
};

type Feedback = {
  by?: string;
  ratings: Array<UserRating>;
  comment: string;
};

type FinalRatings = {
  [parentId: string]: number;
  totalRatings: number;
};

type OldRatings = {
  [parentId: string]: number;
  totalRatings: number;
};

export const calculateFinalRatings = (
  feedbackQuestions: FeedbackQuestion[],
  userRatings: UserRating[],
  oldRatings: OldRatings
): FinalRatings => {
  const questionWeightageMap: {
    [questionId: string]: { parentId: string; weightage: number };
  } = {};

  // Create a map of questionId to its parentId and weightage
  feedbackQuestions.forEach((question) => {
    questionWeightageMap[question.questionId] = {
      parentId: question.parentId,
      weightage: question.weightage,
    };
  });

  // Group ratings by parentId
  const parentIdRatingsMap: {
    [parentId: string]: { totalRating: number; totalWeightage: number };
  } = {};

  userRatings.forEach((rating) => {
    const { questionId, rating: userRating } = rating;
    const { parentId, weightage } = questionWeightageMap[questionId] || {};

    if (!parentId) return;

    if (!parentIdRatingsMap[parentId]) {
      parentIdRatingsMap[parentId] = { totalRating: 0, totalWeightage: 0 };
    }

    parentIdRatingsMap[parentId].totalRating += userRating * weightage;
    parentIdRatingsMap[parentId].totalWeightage += weightage;
  });

  // Calculate the final ratings for each parentId, including old ratings
  const finalRatings: FinalRatings = { ...oldRatings };
  const newTotalRatings = (oldRatings?.totalRatings || 0) + 1;

  Object.keys(parentIdRatingsMap).forEach((parentId) => {
    const { totalRating, totalWeightage } = parentIdRatingsMap[parentId];
    const oldRating = oldRatings[parentId] || 0;

    const combinedRating =
      (oldRating * (oldRatings?.totalRatings || 0) +
        totalRating / totalWeightage) /
      newTotalRatings;
    finalRatings[parentId] = combinedRating;
  });

  // Update the totalRatings count
  finalRatings.totalRatings = newTotalRatings;

  return finalRatings;
};
