export enum CreatorQuestionIds {
  OCCUPATION = "occupation",
  NAME = "name",
  WHERE_ARE_YOU_LOCATED = "location",
  LET_PEOPLE_KNOW_ABOUT_YOU = "favoriteQuote",
  WHAT_IS_YOUR_COMPANYS_NAME = "companyName",
  HOW_MANY_YEARS_OF_TOTAL_EXPERIENCE_DO_YOU_HAVE = "experienceYears",
  HOW_MANY_YEARS_OF_WORK_EXPERIENCE_DO_YOU_HAVE = "workExperienceYears",
  HOW_MANY_PROJECTS_HAVE_YOU_COMPLETED = "completedProjects",
  HOW_MANY_LANGUAGES_DO_YOU_SPEAK = "languages",
  WHAT_IS_YOUR_AVERAGE_PROJECT_COST = "projectCost",
  WHAT_TYPE_OF_PROJECTS_DO_YOU_WORK_ON = "projectType",
  UPLOAD_YOUR_SITE_PHOTOGRAPHS = "photos",
  PROFILE_PICTURE = "profilePicture",
  CREATOR_PHOTOS = "creatorPhotos",
  WEBSITE_URL = "websiteUrl",
  INSTAGRAM_LINK = "instagramLink",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  CONTACT_NUMBER = "contactNumber",
}
