import { useState } from "react";
import { useRecoilState } from "recoil";

import { initiatePayment } from "./../../services/razorpay/razorpay-service";
import { useAlerts } from "../alert/use-alert";
import { useCreator } from "../creator/use-creator";
import { CreatorProfile, LoggedInUser } from "models";
import { onSnapshot, doc, db } from "config";
import { loggedInUserAtom } from "../../data/user-atoms";

export const useRazorpay = () => {
  const { addAlert } = useAlerts();
  const { setCreatorProfile, resetSummaries, creatorProfile } = useCreator();
  const [isLoading, setIsLoading] = useState(false);
  const [, setLoggedInUser] = useRecoilState<LoggedInUser | null>(
    loggedInUserAtom
  );

  const initiateRazorPayment = async (
    planId: string,
    loggedInUser: LoggedInUser,
    callback: (isSuccess: boolean) => void
  ) => {
    const handleSuccess = () => {
      addAlert({
        type: "success",
        message: "All Set ! Complete your profile to enjoy Premium Feature.",
      });
      setIsLoading(false);
      callback(true);
    };

    const handleError = () => {
      addAlert({
        type: "error",
        message:
          "Currently we are facing some issues , please try again later or contact support.",
      });
      setIsLoading(false);
      callback(false);
    };

    // Start loading
    setIsLoading(true);
    initiatePayment(planId, loggedInUser, handleError);
    // Set up Firestore listener for subscription status
    const userDocRef = doc(db, `users/${loggedInUser.userId}`);

    const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        const isPremiumActive = userData?.subscription?.active;

        if (isPremiumActive && loggedInUser) {
          setLoggedInUser(userData as LoggedInUser);
          setCreatorProfile({
            ...creatorProfile,
            isSubscribed: true,
          } as CreatorProfile);
          resetSummaries();
          handleSuccess();
          unsubscribe(); // Stop listening once the subscription is active
        }
      } else {
        // Handle case where user document does not exist
        addAlert({
          type: "error",
          message: "Unauthorized user.",
        });
        setIsLoading(false);
        callback(false);
        unsubscribe(); // Stop listening if the document does not exist
      }
    });
  };
  return {
    initiateRazorPayment,
    isLoading,
  };
};
