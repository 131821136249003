import React, { useEffect, useState } from "react";
import styles from "./Alert.module.css";
import { AlertProps } from "./AlertProps";
import { Box } from "../box";
import { Appsettings } from "config";
import { Modal } from "../modal";

const Alert: React.FC<AlertProps> = ({
  message,
  type,
  onClickClose,
  displayCross,
  autoHide = true,
  structure = "notification",
  modalTitle,
  modalDescription,
  index,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (autoHide) {
        setIsVisible(false);
        onClickClose && onClickClose(0);
      }
    }, Appsettings.autoHideDuration);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isVisible && (
        <>
          {structure === "notification" ? (
            <Box backgroundColor="white">
              <Box
                className={`${styles.alert} ${styles[type]} ${styles.slideIn} alert-component`}
              >
                {message}
                {displayCross && (
                  <button
                    onClick={() => {
                      setIsVisible(false);
                      onClickClose && onClickClose(index || 0);
                    }}
                    className={styles.closeButton}
                  >
                    &times;
                  </button>
                )}
              </Box>
            </Box>
          ) : (
            <Modal
              title={modalTitle}
              isOpen={true}
              onClose={() => {
                setIsVisible(false);
                onClickClose && onClickClose(index || 0);
              }}
            >
              {modalDescription}
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export default Alert;
