import React, { useState, useEffect } from "react";
import "./Autocomplete.css";
import { RegularInput } from "../regular-input";

interface AutocompleteProps {
  suggestions: string[];
  onSelect: (value: string) => void;
  onChange: (value: string) => void;
  userInput: string; // User input comes from props
  placeholder?: string;
  onEnter?: () => void;
}

const Autocomplete: React.FC<AutocompleteProps> = ({
  suggestions,
  onSelect,
  onChange,
  userInput,
  placeholder,
  onEnter,
  ...rest
}) => {
  const [active, setActive] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    onChange(userInput); // Call the onChange callback whenever userInput changes
  }, [userInput]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value;
    onChange(inputValue);
    setShowSuggestions(true);
  };

  const onClick = (e: React.MouseEvent<HTMLLIElement>) => {
    const selectedValue = e.currentTarget.innerText;
    setActive(0);
    setShowSuggestions(false);
    onChange(selectedValue);
    onSelect(selectedValue);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const selectedSuggestion = suggestions[active];
      setActive(0);
      setShowSuggestions(false);
      onChange(selectedSuggestion);
      onSelect(selectedSuggestion);
    } else if (e.key === "ArrowUp") {
      if (active === 0) {
        return;
      }
      setActive(active - 1);
    } else if (e.key === "ArrowDown") {
      if (active === suggestions.length - 1) {
        return;
      }
      setActive(active + 1);
    }
  };

  const SuggestionsListComponent = () => {
    return suggestions.length ? (
      <ul className="dropdown-list">
        {suggestions.map((suggestion, index) => {
          let className =
            index === active ? "dropdown-item active" : "dropdown-item";
          return (
            <li className={className} key={suggestion} onClick={onClick}>
              <span>{suggestion}</span>
            </li>
          );
        })}
      </ul>
    ) : (
      <div className="dropdown-item">
        <span>No suggestions available.</span>
      </div>
    );
  };

  return (
    <div className="dropdown">
      <RegularInput
        type="text"
        onChange={onInputChange}
        onKeyDown={onKeyDown}
        value={userInput} // Controlled input from props
        placeholder={placeholder}
        onEnter={onEnter}
        {...rest}
      />
      {showSuggestions && <SuggestionsListComponent />}
    </div>
  );
};

export default Autocomplete;
