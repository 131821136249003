import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUser } from "backend";
import { AppRoutes } from "../../constants/appRoutes";

interface AuthRouteProps {
  component: React.ComponentType;
  path?: string;
}

const AuthRoute: React.FC<AuthRouteProps> = ({ component: Component }) => {
  const { loggedInUser } = useUser();
  const location = useLocation();

  if (!loggedInUser?.email) {
    // Redirect to the login page, but pass the current location as state
    // to come back to after successful login
    return <Navigate to={AppRoutes.LOGIN} state={{ from: location }} replace />;
  }

  return <Component />;
};

export default AuthRoute;
