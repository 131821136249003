import { useRecoilState } from "recoil";
import { isPremiumModalOpenAtom } from "../../data/premium-atoms";
import { LOCAL_STORAGE_KEYS } from "reusable";

export const useModal = () => {
  const [isPremiumModalOpen, setIsPremiumModalOpenState] = useRecoilState(
    isPremiumModalOpenAtom
  );

  const setIsPremiumModalOpen = (val: boolean) => {
    setIsPremiumModalOpenState(val);
    localStorage.setItem(LOCAL_STORAGE_KEYS.IS_ASKED_FOR_PREMIUM, "true");
  };

  return { isPremiumModalOpen, setIsPremiumModalOpen };
};
