import { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Colors,
  Text,
  Button,
  TextVariants,
  AppRoutes,
  Link,
  BurgerMenu,
  MenuCross,
  GLOBAL_STYLES,
  HeaderLogo,
  dummyUserId,
  ProfileBadge,
  MobileHeaderLogo,
  Alert,
  GetPremiumButton,
  GetPremiumButtonMobile,
} from "reusable";
import { useUser, useModal, useAlerts, useCreator } from "backend";

import { HeaderProps } from "./HeaderProps";
import "./Header.css";
import { LoggedInUser } from "models";
import { ProfileBanner } from "../../screens/profile/components/ProfileBanner";

const Header = (props: HeaderProps): JSX.Element => {
  //imports
  const { display = true } = props;
  const { loggedInUser, logout } = useUser();
  const { creatorProfile } = useCreator();
  const { setIsPremiumModalOpen } = useModal();
  const currentRoute = useLocation().pathname;
  const goTo = useNavigate();
  const {
    email,
    isCreatorQuestionsAnswered,
    userId = dummyUserId,
    name,
    profilePicture,
  } = (loggedInUser || {}) as LoggedInUser;
  const { alerts, removeAlert } = useAlerts();

  //states
  const [headerOptions, setHeaderOptions] = useState<
    Array<Record<string, string | ReactNode>>
  >([]);
  const [mobileOptions, setMobileOptions] = useState<
    Array<Record<string, string | ReactNode>>
  >([]);
  const [burgerMenuOptions, setBurgerMenuOptions] = useState<
    Array<Record<string, string | ReactNode>>
  >([]);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const isLoggedIn = Boolean(email);
  const profileCreated = isLoggedIn && isCreatorQuestionsAnswered;

  useEffect(() => {
    setHeaderOptions([
      {
        id: "pricing",
        element: (
          <Box
            marginTop="5px"
            cursor="pointer"
            onClick={() => setIsPremiumModalOpen(true)}
          >
            <GetPremiumButton />
          </Box>
        ),
        display:
          !loggedInUser?.subscription?.active &&
          isLoggedIn &&
          loggedInUser?.isCreatorQuestionsAnswered,
      },
      {
        id: "login",
        element: (
          <Box padding="15px">
            <Link onClick={() => goTo(AppRoutes.LOGIN)}>
              <Text
                variant={TextVariants.MEDIUM_PRIMARY_FONT_BOLD}
                color={Colors.BLACK}
              >
                Login
              </Text>
            </Link>
          </Box>
        ),
        display: !isLoggedIn,
      },
      {
        id: "create-page",
        element: (
          <Box marginLeft="8px">
            <Button
              className="create-page-button"
              onClick={() => {
                const route = profileCreated
                  ? AppRoutes.PROFILE.replace(":userId", userId)
                  : AppRoutes.FILL_PROFILE_DETAILS.replace(":userId", userId);
                goTo(route);
              }}
            >
              <Text
                variant={TextVariants.MEDIUM_PRIMARY_FONT_BOLD}
                color={Colors.WHITE}
              >
                Create Profile
              </Text>
            </Button>
          </Box>
        ),
        display: !profileCreated && !currentRoute.includes("fill-details"),
      },
      {
        id: "profile-badge",
        element: (
          <Box margin="0 0 0 16px">
            <ProfileBadge
              profilePicture={profilePicture || ""}
              name={name}
              onClick={() =>
                profileCreated
                  ? goTo(AppRoutes.PROFILE.replace(":userId", userId))
                  : ""
              }
            />
          </Box>
        ),
        display: isLoggedIn,
      },
      {
        id: "logout",
        element: (
          <Box padding="15px">
            <Link onClick={logout}>
              <Text
                variant={TextVariants.MEDIUM_PRIMARY_FONT_BOLD}
                color={Colors.BLACK}
              >
                Logout
              </Text>
            </Link>
          </Box>
        ),
        display: isLoggedIn,
      },
    ]);

    setMobileOptions([
      {
        id: "pricing",
        element: (
          <Box
            marginTop="7px"
            cursor="pointer"
            onClick={() => setIsPremiumModalOpen(true)}
          >
            <GetPremiumButtonMobile />
          </Box>
        ),
        display:
          !loggedInUser?.subscription?.active &&
          isLoggedIn &&
          loggedInUser?.isCreatorQuestionsAnswered,
      },
      {
        id: "login",
        element: (
          <Link onClick={() => goTo(AppRoutes.LOGIN)}>
            <Text
              variant={TextVariants.MEDIUM_PRIMARY_FONT_BOLD}
              color={Colors.GRAY_1}
              padding="5px 13.5px"
            >
              Login
            </Text>
          </Link>
        ),
        display: !isLoggedIn && !isMenuOpen,
      },
      {
        id: "create-page",
        element: (
          <Button
            className="create-page-button"
            onClick={() => {
              const route = profileCreated
                ? AppRoutes.PROFILE.replace(":userId", userId)
                : AppRoutes.FILL_PROFILE_DETAILS.replace(":userId", userId);
              goTo(route);
            }}
          >
            <Text
              variant={TextVariants.MEDIUM_PRIMARY_FONT_BOLD}
              color={Colors.WHITE}
            >
              Create Profile
            </Text>
          </Button>
        ),
        display:
          !profileCreated &&
          !currentRoute.includes("fill-details") &&
          !isMenuOpen,
      },
      {
        id: "profile-badge",
        element: (
          <Box margin="0 -18px 0 8px">
            <ProfileBadge
              profilePicture={profilePicture || ""}
              name={name}
              onClick={() =>
                profileCreated
                  ? goTo(AppRoutes.PROFILE.replace(":userId", userId))
                  : ""
              }
            />
          </Box>
        ),
        display: isLoggedIn,
      },
      {
        id: "burger",
        element: (
          <>
            {
              <Box
                marginLeft="8px"
                maxHeight="42px"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                {isMenuOpen ? <MenuCross /> : <BurgerMenu />}
              </Box>
            }
          </>
        ),
        display: true,
      },
    ]);

    setBurgerMenuOptions([
      {
        id: "Find-Designer",
        element: (
          <Link
            onClick={() => {
              goTo(AppRoutes.EXPLORE_DESIGNERS);
              setIsMenuOpen(false);
            }}
          >
            <Text
              marginBottom="40px"
              variant={TextVariants.HEADING_TERNARY_FONT}
              color={Colors.BLACK_DARK}
            >
              Find Designer
            </Text>
          </Link>
        ),
        display: true,
      },
      {
        id: "about-us",
        element: (
          <Box>
            <Link
              onClick={() => {
                goTo(AppRoutes.ABOUT_US);
                setIsMenuOpen(false);
              }}
            >
              <Text
                marginBottom="40px"
                variant={TextVariants.HEADING_TERNARY_FONT}
                color={Colors.BLACK_DARK}
              >
                About Us
              </Text>
            </Link>
          </Box>
        ),
        display: true,
      },
      {
        id: "contact-us",
        element: (
          <Box>
            <Link
              onClick={() => {
                goTo(AppRoutes.CONTACT_US);
                setIsMenuOpen(false);
              }}
            >
              <Text
                marginBottom="40px"
                variant={TextVariants.HEADING_TERNARY_FONT}
                color={Colors.BLACK_DARK}
              >
                Contact Us
              </Text>
            </Link>
          </Box>
        ),
        display: true,
      },
      {
        id: "logout",
        element: (
          <Box>
            <Link onClick={logout}>
              <Text
                marginBottom="40px"
                variant={TextVariants.HEADING_TERNARY_FONT}
                color={Colors.BLACK_DARK}
              >
                Logout
              </Text>
            </Link>
          </Box>
        ),
        display: isLoggedIn,
      },
    ]);
  }, [loggedInUser, currentRoute, isMenuOpen]);

  return (
    <>
      {display && (
        <>
          <Box className="header-container">
            <Box className="header">
              <Box
                className="header-logo"
                display="flex"
                onClick={() => goTo("/")}
                cursor="pointer"
              >
                {currentRoute !== AppRoutes.LANDING && <HeaderLogo />}
              </Box>
              <Box className="header-options">
                {headerOptions
                  .filter((option) => option.display)
                  .map((opt) => (
                    <Box display="inline-flex" key={opt.id as string}>
                      {opt.element}
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
          <Box
            className="show-for-small-only"
            height={GLOBAL_STYLES.MOBILE_HEADER_HEIGHT}
          >
            <Box
              className="mobile-header-container"
              height={isMenuOpen ? "559px" : GLOBAL_STYLES.MOBILE_HEADER_HEIGHT}
            >
              <Box
                className="mobile-header"
                height={GLOBAL_STYLES.MOBILE_HEADER_HEIGHT}
              >
                <Box
                  className="mobile-header-logo"
                  display="flex"
                  onClick={() => goTo("/")}
                  cursor="pointer"
                >
                  <MobileHeaderLogo />
                </Box>
                <Box className="mobile-header-options">
                  {mobileOptions
                    .filter((option) => option.display)
                    .map((opt) => (
                      <Box
                        display="inline-flex"
                        key={opt.id as string}
                        className="mobile-header-option"
                      >
                        {opt.element}
                      </Box>
                    ))}
                </Box>
              </Box>
              {isMenuOpen && (
                <Box className="burger-menu" textAlign="center">
                  {burgerMenuOptions
                    .filter((option) => option.display)
                    .map((opt) => (
                      <Box display="block" key={opt.id as string}>
                        {opt.element}
                      </Box>
                    ))}
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="40px"
                  >
                    <Button
                      width="258px"
                      height="48px"
                      className="create-page-button burger-create-page-button"
                      onClick={() => {
                        const route = profileCreated
                          ? AppRoutes.PROFILE.replace(":userId", userId)
                          : AppRoutes.FILL_PROFILE_DETAILS.replace(
                              ":userId",
                              userId
                            );
                        goTo(route);
                        setIsMenuOpen(false);
                      }}
                    >
                      <Text
                        variant={TextVariants.MEDIUM_PRIMARY_FONT_BOLD}
                        color={Colors.WHITE}
                      >
                        Create a Page For Free
                      </Text>
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          {alerts?.map((alert, index) => (
            <Box key={`${alert.message + index}`}>
              <Alert {...alert} onClickClose={removeAlert} index={index} />
            </Box>
          ))}
          {currentRoute?.includes("profile") && (
            <ProfileBanner profile={creatorProfile} />
          )}
        </>
      )}
    </>
  );
};

export default Header;
