import { useState, useEffect } from "react";
import { useAlerts } from "../alert/use-alert";

export const useInternetConnection = () => {
  const { addAlert, removeAlert } = useAlerts();
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      if (hasLoaded) {
        removeAlert(0);
        addAlert({
          type: "success",
          message: "Reconnected with Internet",
          autoHide: true,
        });
      }
      setHasLoaded(true);
    };

    const handleOffline = () => {
      if (hasLoaded || !navigator.onLine) {
        addAlert({
          type: "error",
          message: "Internet Connection Lost",
          autoHide: false,
          structure: "modal",
          modalTitle: "Oops ! Internet Connection Lost",
          modalDescription:
            "Please check your internet connection or try again later.",
        });
      }
      setHasLoaded(true);
    };

    // Initial check if internet is available or not
    if (!hasLoaded) {
      if (!navigator.onLine) {
        handleOffline();
      } else {
        handleOnline();
      }
    }

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Cleanup
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [hasLoaded]);
};

export default useInternetConnection;
