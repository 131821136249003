import Compressor from "compressorjs";
import { Appsettings } from "config";

export const compressImage = (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: Appsettings.imageQuality, // 0 to 1, where 1 is highest quality
      maxWidth: Appsettings.imageMaxWidth,
      maxHeight: Appsettings.imageMaxHeight,
      success(result: any) {
        resolve(result);
      },
      error(err) {
        reject(err);
      },
    });
  });
};
