import { Colors, TextVariants } from "reusable";
import { Avatar } from "../avatar";
import { Box } from "../box";
import { Text } from "../text";
import "./ProfileBadge.css";

const ProfileBadge = (props: {
  profilePicture: string;
  name: string;
  onClick: () => void;
}): JSX.Element => {
  const { profilePicture, name, onClick } = props;

  return (
    <Box className="profile-badge" onClick={onClick}>
      <Box className="profile-badge-avatar">
        <Avatar
          url={profilePicture}
          height="38px"
          width="38px"
          componentKey="profile-badge"
          name={name}
        />
      </Box>
    </Box>
  );
};

export default ProfileBadge;
