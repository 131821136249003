import { Feedback, ProfileSummary } from "models";
import { dummyImageUrl } from "./dummyData";

export const generateSearchTerms = (
  searchFields: Array<string>,
  profileData: any
) => {
  let terms = new Set<string>();

  searchFields.forEach((field) => {
    const fieldValue = profileData[field];
    if (fieldValue && typeof fieldValue === "string") {
      // Convert string to lowercase
      const lowerFieldValue = fieldValue.toLowerCase();
      // Split the field value into words by spaces and commas, suitable for names and locations
      const words = lowerFieldValue
        ?.split(/[\s,]+/)
        .filter((word) => word.length >= 3); // Consider words with at least 3 characters

      // Add each word to the terms set
      words.forEach((word) => terms.add(word));

      // Add the entire field value if it makes sense (e.g., not for long descriptions)
      if (lowerFieldValue.length < 50) {
        // Arbitrarily choosing 50 as a cutoff
        terms.add(lowerFieldValue);
      }

      // Handle specific combinations and permutations
      if (field === "name" && words.length > 1) {
        // Add permutations for names: "Jane Doe", "Doe Jane"
        terms.add(words.join(" "));
        terms.add(words.reverse().join(" "));
      }
    }
  });

  return Array.from(terms);
};
export const capitalize = (s: string) => {
  if (typeof s === "string") {
    return (
      s
        ?.split(" ")
        .map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
        .join(" ") || s
    );
  }
  return s;
};

export async function fileListToBase64Array(
  fileList: FileList | null | string[]
): Promise<string[]> {
  // Check if fileList is an array of strings containing URLs
  if (
    Array.isArray(fileList) &&
    fileList.length > 0 &&
    typeof fileList[0] === "string" &&
    (fileList[0].includes("https://") || fileList.includes("https://"))
  ) {
    return fileList as string[];
  }

  // If fileList is null or empty, return an empty array
  if (!fileList || fileList.length === 0) {
    return [];
  }

  const base64Array: string[] = [];
  const promises: Promise<void>[] = [];

  // Convert each file in the FileList to a base64 string
  for (let i = 0; i < fileList.length; i++) {
    const file = fileList[i];
    if (file instanceof File) {
      const promise = new Promise<void>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result?.toString() || "";
          base64Array.push(base64String);
          resolve();
        };
        reader.onerror = () => {
          reject(new Error("Error reading file"));
        };
        reader.readAsDataURL(file);
      });
      promises.push(promise);
    }
  }

  // Wait for all promises to resolve
  await Promise.all(promises);
  return base64Array;
}

function base64ToFile(base64Array: string[]): File[] {
  return base64Array.map((base64String, index) => {
    const byteString = atob(base64String.split(",")[1]);
    const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    const filename = `file_${index}`;
    return new File([blob], filename, { type: mimeString });
  });
}

export function base64ToFileList(base64Array: string[]): FileList {
  if (
    Array.isArray(base64Array) &&
    base64Array.length > 0 &&
    typeof base64Array[0] === "string" &&
    (base64Array[0].includes("https://") || base64Array.includes("https://"))
  ) {
    // If the array contains URLs, return the array as a FileList (assuming this is intentional)
    return base64Array as unknown as FileList;
  }

  const files = base64ToFile(base64Array);
  const fileList = new DataTransfer();

  files.forEach((file) => {
    fileList.items.add(file as File);
  });

  return fileList.files;
}

export function scrollHorizontally(pixels: number) {
  window.scrollBy(pixels, 0); // Pass pixels for horizontal scroll, 0 for no vertical scroll
}

//process urls
export const isWIthoutUrl = (item: string) =>
  !item?.includes("https://") && !item?.includes(dummyImageUrl);

const convertFilesToUrls = async (files: FileList): Promise<string[]> => {
  const urls = Array.from(files).map((file) => URL.createObjectURL(file));
  return urls;
};

export const processUrls = async (urls: any): Promise<string[]> => {
  if (urls) {
    if (!new URL(String(urls)) && typeof urls !== "string") {
      const fileUrls = await convertFilesToUrls(urls as unknown as FileList);
      return fileUrls;
    }
  }

  return urls as unknown as string[];
};

const toRadians = (degrees: number): number => degrees * (Math.PI / 180);

export const haversineDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  const R = 6371; // Radius of Earth in kilometers
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in kilometers
};
