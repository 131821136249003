import { Colors, TextVariants } from "reusable";
import { Box } from "../box";
import { Text } from "../text";

import "./RoleDisplayer.css";

const RoleDisplayer = (props: {
  name: string;
  theme?: string;
  color?: string;
}): JSX.Element => {
  const { name, theme, color } = props;
  return (
    <Box className={`role-displayer ${theme}`}>
      <Text color={color || ""} variant={TextVariants.MEDIUM_PRIMARY_FONT_BOLD}>
        {name}
      </Text>
    </Box>
  );
};

export default RoleDisplayer;
