import { httpsCallable, functions } from "config";
import { Email } from "models";

export const NotificationService = {
  handleSendEmail: async (
    email: Email,
    handleSuccess: () => void,
    handleError: () => void
  ) => {
    const sendEmail = httpsCallable(functions, "sendEmail");
    try {
      if (email?.to) {
        await sendEmail({
          ...email,
        });
        handleSuccess();
      }
    } catch (error) {
      console.error("Error sending email:", error);
      handleError();
    }
  },
};
