import { Location } from "models";

export const LocationService = {
  getUserLocation: async (
    handleSuccess: (location: Location) => void,
    handleError: () => void
  ): Promise<void> => {
    try {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Location successfully obtained
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          handleSuccess({ lat, lon });
        },
        (error) => {
          // Location fetching failed
          handleError();
        }
      );
    } catch (error) {
      // Catch any unexpected errors
      handleError();
    }
  },
};

export default LocationService;
