// useHelmet.tsx
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

interface HelmetProps {
  title?: string;
  description?: string;
  themeColor?: string;
}

export const useHelmet = (initialProps: HelmetProps) => {
  const [helmetProps, setHelmetProps] = useState<HelmetProps>(initialProps);

  useEffect(() => {
    // This effect can be used to log changes or trigger side effects when meta tags are updated.
  }, [helmetProps]);

  const HelmetComponent = () => (
    <Helmet>
      {helmetProps.title && <title>{helmetProps.title}</title>}
      {helmetProps.description && (
        <meta name="description" content={helmetProps.description} />
      )}
      {helmetProps.themeColor && (
        <meta name="theme-color" content={helmetProps.themeColor} />
      )}
    </Helmet>
  );

  return { HelmetComponent, setHelmetProps };
};
