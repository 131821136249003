import { useEffect } from "react";

import {
  Box,
  PhotoExpandCross,
  PhotoExpandNext,
  PhotoExpandPrevious,
} from "reusable";

const PhotoExpandar = (props: {
  expandImageIndex: number;
  setExpandImageIndex: React.Dispatch<React.SetStateAction<number>>;
  existingUrls: Array<string>;
}): JSX.Element => {
  const { expandImageIndex, setExpandImageIndex, existingUrls } = props;

  const handleKeyDown = (event: KeyboardEvent) => {
    if (expandImageIndex > -1) {
      switch (event.key) {
        case "ArrowRight":
          console.log(expandImageIndex, "right");
          setExpandImageIndex(
            expandImageIndex < existingUrls?.length - 1
              ? expandImageIndex + 1
              : -1
          );
          break;
        case "ArrowLeft":
          setExpandImageIndex((prev) => prev - 1);
          break;
        case "Escape":
          setExpandImageIndex(-1);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    // Attach the event listener
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [expandImageIndex]);

  return (
    <>
      <Box className="image-expander hide-for-small-only">
        <Box
          onClick={() => setExpandImageIndex((prev) => prev - 1)}
          cursor="pointer"
        >
          <PhotoExpandPrevious />
        </Box>
        <Box className="expanded-image-container">
          <img
            src={existingUrls[expandImageIndex]}
            className="expanded-image"
          />
          <Box
            cursor="pointer"
            onClick={() => setExpandImageIndex(-1)}
            className="photo-expand-cross"
          >
            <PhotoExpandCross />
          </Box>
        </Box>

        <Box
          onClick={() =>
            setExpandImageIndex((prev) =>
              prev < existingUrls?.length - 1 ? prev + 1 : -1
            )
          }
          cursor="pointer"
        >
          <PhotoExpandNext />
        </Box>
      </Box>
    </>
  );
};

export default PhotoExpandar;
